import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectors, actions } from 'store';
import { Form, Icon } from 'semantic-ui-react';

import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { FiscalPeriod } from 'components/Form';
import { monitoringFiltersSchema } from 'interfaces/formSchema/customsValidators';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';

const SearchFilters = ({
  dispatch,
  applyFilters,
  searchLoading,
  resetFilters,
  isVisible,
  companies,
  customsOfficesLoading,
  customsOffices,
}: any) => {
  const [companiesList, setcompaniesList] = useState([]);
  const [officesList, setofficesList] = useState([]);

  type session = {
    id: string,
    company: number,
    startDate: string,
    endDate: string,
    licensePlate: string,
    customsOffices: string,
    dsceNumber: number,
    intranetReference: string,
    clientReference: string,
    purchaseNumber: string,
    showAll: boolean,
  }

  const data: session = JSON.parse(sessionStorage.getItem('customs_search'))

  useEffect(() => {
    if (isVisible) {
      dispatch(actions.customsDeclaration.getCompanySubscribed());
      dispatch(actions.customsDeclaration.getAllCustomsOffice());
    } else {
      dispatch(
        actions.customsDeclaration.reset(['companySubscribed', 'customsOffices', 'error'])
      );
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    if (isVisible && companies) {
      setcompaniesList(
        companies.map((company, index) => {
          return {
            key: index,
            value: company.id,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
  }, [companies, isVisible]);

  useEffect(() => {
    if (isVisible && customsOffices) {
      setofficesList(
        customsOffices.map((offices, index) => {
          return {
            key: index,
            value: offices.id,
            text: offices.name,
          };
        })
      );
    }
  }, [customsOffices, isVisible]);

  return (
    <Segment
      {...(!isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        initialValues={data ? {
          id: data.id || '',
          company: data.company || undefined,
          startDate: data.startDate || '',
          endDate: data.endDate || '',
          licensePlate: data.licensePlate || '',
          customsOffices: data.customsOffices || '',
          dsceNumber: data.dsceNumber || '',
          intranetReference: data.intranetReference || '',
          clientReference: data.clientReference || '',
          purchaseNumber: data.purchaseNumber || '',
          showAll: true,
        } : {
          id: '',
          company: undefined,
          startDate: '',
          endDate: '',
          licensePlate: '',
          customsOffices: '',
          dsceNumber: '',
          intranetReference: '',
          clientReference: '',
          purchaseNumber: '',
          showAll: true,
        }}
        onSubmit={applyFilters}
        validationSchema={monitoringFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
              <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='From'
                    name='startDate'
                    component={FiscalPeriod}
                    meta={{ errors, touched, setFieldValue, setFieldTouched }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='To'
                    name='endDate'
                    component={FiscalPeriod}
                    meta={{ errors, touched, setFieldValue, setFieldTouched }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='CHED number'
                    name='dsceNumber'
                    component={Input}
                    meta={{ errors, touched, setFieldValue, setFieldTouched }}
                  />
                </Wrapper>
              </Wrapper>
              <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
              <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='Id'
                    name='id'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='Company'
                    name='company'
                    options={companiesList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='License Plate'
                    name='licensePlate'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='Customs office'
                    name='customsOffices'
                    options={officesList}
                    loading={customsOfficesLoading}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='Intranet reference'
                    name='intranetReference'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='Client reference'
                    name='clientReference'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='11%'>
                  <Field
                    placeholder='Purchase order'
                    name='purchaseNumber'
                    type='text'
                    component={Input}
                    meta={{ errors, touched }}
                    margin='20px'
                  />
                </Wrapper>
              </Wrapper>
              {/* <Wrapper display='flex' margin='0.5rem' justifyContent='center'>
                <Field
                  label='Show archived'
                  name='showAll'
                  component={Checkbox}
                  checked={values.showAll}
                  onClick={() => {
                    !values.showAll
                      ? setFieldValue('showAll', true)
                      : setFieldValue('showAll', false);
                  }}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Wrapper> */}
            </Wrapper>
            <Wrapper display='flex' margin='1rem' justifyContent='center'>
              <Button
                disabled={searchLoading}
                {...(searchLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetFilters();
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {
    companies: selectors.customsDeclaration.companySubscribedSelector(state),
    customsOffices: selectors.customsDeclaration.getAllCustomsOfficeSelector(state),
    customsOfficesLoading: selectors.customsDeclaration.getAllCustomsOfficeLoadingSelector(state),
    searchLoading: selectors.customsDeclaration.loadingSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(SearchFilters);
