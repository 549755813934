import React from 'react';
import { Menu, Icon, Image } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';

import NavLinkImg from 'components/NavLinkImg';
import images from 'assets/images';
import Wrapper from 'components/core/Wrapper/Wrapper';

import {
  MenuItemStyled,
  MenuItemWrapper,
  DropdownItemStyled,
  DropdownMenuStyled,
  DropdownStyled,
  SidebarStyled,
  SidebarPushable,
  SidebarPusher,
} from './styles';
import TextCustom from 'components/core/TextCustom/TextCustom';

const Index = ({
  dispatch,
  user,
  logout,
  children,
  isAuthenticated,
  setvisible,
  visible,
}: any) => {
  const onMenuItemClick = () => {
    dispatch(actions.message.reset(['httpCode', 'getError', 'message']));

    setvisible(false);
  };

  return (
    <SidebarPushable as={Wrapper} width='100%' minHeight='100%'>
      {isAuthenticated && (
        <SidebarStyled
          as={Menu}
          animation='overlay'
          icon='labeled'
          inverted
          onHide={() => {
            setvisible(false);
          }}
          vertical
          visible={visible}
        >
          <MenuItemWrapper>
            <Menu.Item>
              <NavLinkImg to='/'>
                <Image
                  width='60px'
                  src={images.logo_asd_white}
                  onClick={() => onMenuItemClick()}
                />
              </NavLinkImg>
            </Menu.Item>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <DropdownStyled item text='Fiscal' text-align='left'>
              <DropdownMenuStyled>
                {(user.admin || user.operator) && (
                  <>
                    <DropdownItemStyled
                      as={NavLink}
                      to='/fiscal/declaration'
                      onClick={() => onMenuItemClick()}
                    >
                      <TextCustom color='white'>VAT declaration</TextCustom>
                    </DropdownItemStyled>
                    <DropdownItemStyled
                      as={NavLink}
                      to='/fiscal/oss'
                      onClick={() => onMenuItemClick()}
                    >
                      <TextCustom color='white'>OSS</TextCustom>
                    </DropdownItemStyled>
                  </>
                )}
                <DropdownItemStyled
                  as={NavLink}
                  to='/fiscal/kyanite'
                  onClick={() => onMenuItemClick()}
                >
                  <TextCustom color='white'>Quickproof</TextCustom>
                </DropdownItemStyled>
              </DropdownMenuStyled>
            </DropdownStyled>
          </MenuItemWrapper>
          <MenuItemWrapper>
            <DropdownStyled item text='Customs'>
              <DropdownMenuStyled>
                <DropdownItemStyled
                  as={NavLink}
                  to='/customs/declaration'
                  onClick={() => onMenuItemClick()}
                >
                  <TextCustom color='white'>Declaration</TextCustom>
                </DropdownItemStyled>
                {(user.admin || user.operator) && (
                  <DropdownItemStyled
                    as={NavLink}
                    to='/customs/clerk'
                    onClick={() => onMenuItemClick()}
                  >
                    <TextCustom color='white'>Clerk</TextCustom>
                  </DropdownItemStyled>
                )}
              </DropdownMenuStyled>
            </DropdownStyled>
          </MenuItemWrapper>

          {(user.admin || user.operator) && (
            <MenuItemWrapper>
              <DropdownStyled item text='Internal Tools'>
                <DropdownMenuStyled>
                  <DropdownItemStyled
                    as={NavLink}
                    to='/internal-tools/ssr'
                    onClick={() => onMenuItemClick()}
                  >
                    <TextCustom color='white'>SSR</TextCustom>
                  </DropdownItemStyled>
                  <DropdownItemStyled
                    as={NavLink}
                    to='/internal-tools/commercial-offer'
                    onClick={() => onMenuItemClick()}
                  >
                    <TextCustom color='white'>Commercial offer</TextCustom>
                  </DropdownItemStyled>
                </DropdownMenuStyled>
              </DropdownStyled>
            </MenuItemWrapper>
          )}
          <Wrapper textAlign='left'>
            <MenuItemStyled
              as={NavLink}
              to='/companies'
              name='companies'
              onClick={() => onMenuItemClick()}
            />
          </Wrapper>

          {user.admin && (
            // || user.superOp
            <Wrapper textAlign='left'>
              <MenuItemStyled
                as={NavLink}
                to='/agencies'
                name='agencies'
                onClick={() => onMenuItemClick()}
              />
            </Wrapper>
          )}
          {user.admin && (
            // || user.superOp
            <Wrapper textAlign='left'>
              <MenuItemStyled
                as={NavLink}
                to='/operator'
                name='operator'
                onClick={() => onMenuItemClick()}
              />
            </Wrapper>
          )}

          {user.admin && (
            <MenuItemWrapper>
              <DropdownStyled item text='Admin'>
                <DropdownMenuStyled>
                  <DropdownItemStyled
                    as={NavLink}
                    to='/admin/users'
                    onClick={() => onMenuItemClick()}
                  >
                    <TextCustom color='white'>Users</TextCustom>
                  </DropdownItemStyled>
                </DropdownMenuStyled>
              </DropdownStyled>
            </MenuItemWrapper>
          )}

<         Wrapper textAlign='left'>
            <MenuItemStyled
              as={NavLink}
              to='/news'
              name='News'
              onClick={() => onMenuItemClick()}
            />
          </Wrapper>

          <Wrapper textAlign='left'>
            <MenuItemStyled
              as={NavLink}
              to='/profile'
              name='profile'
              onClick={() => onMenuItemClick()}
            />
          </Wrapper>

          {!user.admin && !user.operator && (
            <MenuItemStyled
              as={NavLink}
              to='/contact'
              name='contact us'
              onClick={() => onMenuItemClick()}
            />
          )}

          <Wrapper textAlign='left'>
            <MenuItemStyled
              name='logout'
              onClick={() => {
                onMenuItemClick();
                logout();
              }}
            />
          </Wrapper>

          <Wrapper>
            <MenuItemStyled as='a' onClick={() => setvisible(false)}>
              <Wrapper textAlign='left'>
                <Icon name='close' size='large' />
              </Wrapper>
            </MenuItemStyled>
          </Wrapper>
        </SidebarStyled>
      )}
      <SidebarPusher
        as={Wrapper}
        width='100%'
        minHeight='100%'
        dimmed={visible}
      >
        {children}
      </SidebarPusher>
    </SidebarPushable>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: selectors.auth.isAuthenticatedSelector(state),
    user: selectors.auth.userSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(Index);
