import { Formiz, useForm } from '@formiz/core';
import React from 'react';

import { Header, Modal } from 'semantic-ui-react';

import FormizSimpleInput from 'components/FormizField/FormizSimpleInput/FormizSimpleInput';
import Spacer from 'components/core/Spacer/Spacer';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';

const Index = ({
  open,
  handleAddIntranetReference,
  handleClose,
}: ModalAddIntranetReferenceProps) => {
  const addIntranetReferenceForm = useForm();

  const handeSubmit = (values: { intranetReference: string }) =>
    handleAddIntranetReference(values.intranetReference);

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='Intranet reference' />
      <Formiz connect={addIntranetReferenceForm} onValidSubmit={handeSubmit}>
        <form onSubmit={addIntranetReferenceForm.submit} noValidate>
          <Wrapper margin='20px'>
            <FormizSimpleInput
              name='intranetReference'
              label='Intranet reference'
              placeholder='01D000000000'
              required='Intranet reference is required'
            />
          </Wrapper>
          <Spacer height='20px' />
          <Button type='submit' content='Submit' />
          <Spacer height='20px' />
        </form>
      </Formiz>
    </Modal>
  );
};

interface ModalAddIntranetReferenceProps {
  open: boolean;
  handleAddIntranetReference: (intranetReference: string) => void;
  handleClose: () => void;
}

export default Index;
