import { Field, Formik } from 'formik';
import Dropdown from 'components/Dropdown';
import { selectors } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  Divider,
  Form,
  Grid,
  Header,
  Modal,
  Message,
} from 'semantic-ui-react';
import Button from 'components/Button';


const ModalTagManager = ({
  type,
  open,
  data,
  handleClose,
  handleTagOperations,
}: ModalTagManagerProps) => {

  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content='Manage tags' />
      <Formik
        initialValues={{
          tag: data.tag || '',
          documentId: data.documentId || ''
        }}
        onSubmit={handleTagOperations}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Modal.Content>
            <Message info>Manage document: select new tag and send it to the right repository / change tag</Message>
            <Form onSubmit={handleSubmit}>               
            <Grid.Row>                             
             <Grid.Column width='4'>
                <Field
                  name='tag'
                  placeholder='Choose a tag'
                  component={Dropdown}
                  options={[
                    {key: 1, value: 'intrastat-intr', text: 'Intrastat Intro'},
                    {key: 2, value: 'intrastat-expe', text: 'Intrastat Expe'},
                    {key: 3, value: 'avoir-intr', text: 'Avoir Intro'},
                    {key: 4, value: 'avoir-expe', text: 'Avoir Expe'},
                    {key: 5, value: 'non_declare', text: 'Non déclaré'},
                    {key: 6, value: 'auto_liquidation', text: 'Auto-liquidation'},
                    {key: 7, value: 'non_conforme', text: 'Non conforme'},
                  ]}
                  meta={{
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                  }}
                />
              </Grid.Column>               
              <Divider hidden />
            </Grid.Row>                      
            <Divider hidden />
            <Button
              disabled={!isValid}
              type='submit'
              content='Submit'
              color='green'
            />
          </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

interface ModalTagManagerProps {
  type: number;
  open: boolean;
  declarationType: string;
  data: {
    tag: string;
    documentId: number
  };
  handleAddDocuments: (values: {
    tag: string
    documentId: number
  }) => void;
  getAllFiles: (getAllFiles, index) => void
  handleClose: () => void;
  handleTagOperations: () => void;
  categoriesDocuments?: any;
  categoriesLoading?: boolean;
  dispatch: (value: any) => void;
}

const mapStateToProps = (state) => {
  return {
    categoriesDocuments: selectors.intrastat.getDocsFromCatSelector(state),
    categoriesLoading: selectors.intrastat.getDocsFromCatLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(ModalTagManager);
