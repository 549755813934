import actionTypes from "./actionTypes";
import { dataReqI } from 'interfaces';

export default {
  getPermissions(data: dataReqI) {
    return {
      type: actionTypes.GET_PERMS,
      payload: data,
    };
  },
  getCompanySubscribed() {
    return {
      type: actionTypes.GET_COMPANY_SUBSCRIBED,
    };
  },
  getIntrastatsByFilters(data: dataReqI){
    return {
      type: actionTypes.GET_FILTERED_INTRASTATS,
      payload: data,
    };
  },
  getAllIntrastats(data: dataReqI){
    return {
      type: actionTypes.GET_ALL_INTRASTATS,
      payload: data,
    };
  },
  getOneIntrastat(data: dataReqI){
    return {
      type: actionTypes.GET_ONE_INTRASTAT,
      payload: data,
    };
  },
  createNoneIntrastat(data: dataReqI){
    return {
      type: actionTypes.CREATE_NONE_INTRASTAT,
      payload: data,
    };
  },
  createClientIntrastat(data: dataReqI){
    return {
      type: actionTypes.CREATE_INTRASTAT,
      payload: data,
    };
  },
  updateIntrastat(data: dataReqI){
    return {
      type: actionTypes.UPDATE_INTRASTAT,
      payload: data,
    };
  },
  updateStep(data: dataReqI){
    return {
      type: actionTypes.UPDATE_STEP,
      payload: data,
    };
  },
  followIntrastat(data: dataReqI){
    return {
      type: actionTypes.FOLLOW_INTRASTAT,
      payload: data,
    };
  },
  unfollowIntrastat(data: dataReqI){
    return {
      type: actionTypes.UNFOLLOW_INTRASTAT,
      payload: data,
    };
  },
  addDocuments(data: dataReqI){
    return {
      type: actionTypes.INTRASTAT_ADD_DOCUMENTS,
      payload: data,
    };
  },
  updateDocument(data: dataReqI){
    return {
      type: actionTypes.UPDATE_DOC,
      payload: data,
    };
  },
  getDocsFromCat(data: dataReqI){
    return {
      type: actionTypes.INTRASTAT_GET_DOC_CAT,
      payload: data,
    };
  },
  deleteDocument(data: dataReqI){
    return {
      type: actionTypes.DELETE_DOC,
      payload: data,
    };
  },
  downloadDocument(data: dataReqI){
    return {
      type: actionTypes.DOWNLOAD_DOC,
      payload: data,
    };
  },
  subscribe(data: dataReqI){
    return {
      type: actionTypes.SUBSCRIBE,
      payload: data,
    };
  },
  unsubscribe(data: dataReqI){
    return {
      type: actionTypes.UNSUBSCRIBE,
      payload: data,
    };
  },
  handleTagOperations(data: dataReqI){
    return {
      type: actionTypes.TAG_OPERATION,
      payload: data,
    };
  },
  splitDocument(data: dataReqI){
    return {
      type: actionTypes.SPLIT_DOCUMENT,
      payload: data,
    };
  },
  renameSendChunk(data: dataReqI){
    return {
      type: actionTypes.RENAME_SEND_CHUNCKS,
      payload: data,
    };
  },
  createSingleFile(data: dataReqI){
    return {
      type: actionTypes.SINGLE_FILE,
      payload: data,
    };
  },
  filterTag(data: dataReqI){
    return {
      type: actionTypes.FILTER_TAG,
      payload: data,
    };
  },
  filterTagArchives(data: dataReqI){
    return {
      type: actionTypes.FILTER_TAG_ARCHIVES,
      payload: data,
    };
  },
  deleteFiles(data: dataReqI){
    return {
      type: actionTypes.DELETE_FILES,
      payload: data,
    };
  },
  bulkFilter(data: dataReqI){
    return {
      type: actionTypes.BULK_FILTER,
      payload: data,
    };
  },
  groupFiles(data: dataReqI){
    return {
      type: actionTypes.GROUP_FILES,
      payload: data,
    };
  },
  changeFileState(data: dataReqI){
    return {
      type: actionTypes.FILES_STATE,
      payload: data,
    };
  },
  archiveAll(data: dataReqI){
    return {
      type: actionTypes.ARCHIVE_ALL,
      payload: data,
    };
  },
  getArchiveFile(data: dataReqI){
    return {
      type: actionTypes.GET_ARCHIVE_FILE,
      payload: data,
    };
  },
  getFilesFullList(data: dataReqI){
    return {
      type: actionTypes.GET_ARCHIVE_FILES,
      payload: data,
    };
  },
  getSettings(data: dataReqI){
    return {
      type: actionTypes.SETTINGS_GET,
      payload: data,
    };
  },
  settings(data: dataReqI){
    return {
      type: actionTypes.SETTINGS,
      payload: data,
    };
  },
  reset(keys) {
    return {
      type: actionTypes.RESET,
      payload: {
        keys
      }
    };
  }
};
