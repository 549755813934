import { Field, Formik, FieldArray } from 'formik';
import Dropdown from 'components/Dropdown';
import { selectors, actions } from 'store';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

import {
  Divider,
  Form,
  Grid,
  Header,
  List,
  Modal,
  Transition,
  Message,
} from 'semantic-ui-react';
import Button from 'components/Button';
import { arrayOfCustomsDocuments } from 'interfaces/formSchema/intrastatValidators';
import { Aside, AsideWrapper, DocList, Paragraph, Split } from './styles';
import { Checkbox } from 'components/Form';


const Index = ({
  userAdminOrOp,
  type,
  open,
  declarationType,
  categoriesDocuments,
  categoriesLoading,
  handleAddDocuments,
  handleClose,
  dispatch,
  getAllFiles,
}: ModalAddIntrastatDocumentsProps) => {
  const [categoriesDocumentsList, setCategoriesDocumentsList]: any = useState([]);
  const [fileList, setFileList]: any = useState(null);

  useEffect(() => {
    dispatch(actions.intrastat.getDocsFromCat({
      query: {type: type}
    }));
    return () => {
      dispatch(actions.intrastat.reset(['docsFromCat']));
    };
  }, [dispatch, type]);

  useEffect(() => {
    if (categoriesDocuments) {
          setCategoriesDocumentsList(
            categoriesDocuments.map((category, index) => {
              return {
                key: index,
                value: category.id,
                text: category.fr,
              };
            })
          );
        }
  }, [categoriesDocuments, userAdminOrOp]);


  const constfiles = (acceptedFiles: any[], index: number) => {
    getAllFiles(acceptedFiles, index)
    setFileList(
      acceptedFiles.map(file => (
        <DocList key={file.name}>
          {file.name} - {file.size} bytes
        </DocList>
      )))
  };

  
  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='plus circle' content={`Add ${declarationType} documents`} />
      <Formik
        initialValues={{
          documents: [{ id: undefined, files: [] }],
          split: null,
          type: type,
        }}
        onSubmit={handleAddDocuments}
        validationSchema={arrayOfCustomsDocuments}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
          isValid,
        }) => (
          <Modal.Content>
            <Message info>Allowed files: compressed <strong>.zip</strong> files (will create a single merged pdf), <strong>.pdf</strong>, and <strong>.jpg</strong></Message>
            <Form onSubmit={handleSubmit}>
                <FieldArray
                  name='documents'
                  render={(arrayHelpers) => (
                    <Transition.Group
                      as={List}
                      duration={200}
                      size='medium'
                      verticalAlign='middle'
                    >
                      <Grid columns={userAdminOrOp ? '3' : '2'}>
                        {values.documents.length > 0 &&
                          values.documents.map((document, index) => {
                            return (
                              <Grid.Row key={index}>
                                <Grid.Column width='4'>
                                  <Field
                                    name={`documents[${index}].id`}
                                    arrayName='documents'
                                    placeholder='Choose a Category'
                                    component={Dropdown}
                                    options={categoriesDocumentsList}
                                    loading={categoriesLoading}
                                    meta={{
                                      errors,
                                      touched,
                                      setFieldValue,
                                      setFieldTouched,
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column width={userAdminOrOp ? '8' : '12'}>
                                <Dropzone 
                                  onDrop={acceptedFiles => constfiles(acceptedFiles, index)}>
                                  {({getRootProps, getInputProps}) => (
                                    <section>
                                      <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <Paragraph>Drag 'n' drop some files here, or click to select files</Paragraph>
                                      </div>
                                    {fileList && (
                                      <AsideWrapper>
                                        <Aside>
                                          {fileList}                                  
                                        </Aside>
                                      </AsideWrapper>                                   
                                    )}
                                    </section>
                                  )}
                                </Dropzone>
                                </Grid.Column>
                                {userAdminOrOp && (
                                <Grid.Column width='4'>
                                <Split>
                                  <Field
                                    label='Split document'
                                    name='split'
                                    component={Checkbox}
                                    meta={{
                                      errors,
                                      touched,
                                      setFieldValue,
                                      setFieldTouched,
                                    }}
                                    onChange={() => {
                                      setFieldValue('split', !values.split)
                                    }}
                                  />
                                </Split>
                                </Grid.Column>
                                )}
                              </Grid.Row>
                            );
                          })}
                      </Grid>
                    </Transition.Group>
                  )}
                />
              <Divider hidden />
              <Button
                disabled={!isValid}
                type='submit'
                content='Submit'
                color='green'
              />
            </Form>
          </Modal.Content>
        )}
      </Formik>
    </Modal>
  );
};

interface ModalAddIntrastatDocumentsProps {
  userAdminOrOp: boolean;
  type: number;
  open: boolean;
  declarationType: string;
  handleAddDocuments: (values: {
    documents: Array<{
      files: Array<File>;
      id: number;
    }>;
    type: number;
    split: boolean;
  }) => void;
  getAllFiles: (getAllFiles: any[], index: number) => void
  handleClose: () => void;
  categoriesDocuments?: any;
  categoriesLoading?: boolean;
  dispatch: (value: any) => void;
}

const mapStateToProps = (state) => {
  return {
    categoriesDocuments: selectors.intrastat.getDocsFromCatSelector(state),
    categoriesLoading: selectors.intrastat.getDocsFromCatLoadingSelector(state),
  };
};

const enhance: any = compose(connect(mapStateToProps, null));

export default enhance(Index);
