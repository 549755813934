import React from "react";
import { Field } from "formik";

import { Wrapper } from "./styles";
import { Input } from "components/Form";
import { Form } from "semantic-ui-react";

const Index = ({
  meta,
  placeholder,
  type,
  field,
  name,
  disabled,
  errors,
  touched,
  label,
  margin,
  columns
}: any) => {
  return (
    <Wrapper margin={margin}>
      {/* <Grid columns={columns}>
        <Grid.Row>
          {label && (
            <Grid.Column>
              <label>{label}</label>
            </Grid.Column>
          )}
          <Grid.Column>
            <Field
              name={name}
              type={type}
              component={Input}
              disabled={disabled}
              meta={{ errors, touched }}
              margin={margin}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid> */}

      <Form.Group inline>
        {/* <Grid columns="2">
          <Grid.Row> */}
        {/* <Grid.Column mobile={16} tablet={8} computer={4} widescreen={1}> */}
        {label && <label>{label}</label>}
        {/* </Grid.Column> */}
        {/* <Grid.Column mobile={16} tablet={8} computer={4} widescreen={1}> */}
        <Field
          name={name}
          type={type}
          component={Input}
          disabled={disabled}
          meta={{ errors, touched }}
          margin={margin}
          labelPosition="left"
        />
        {/* </Grid.Column>
          </Grid.Row>
        </Grid> */}
      </Form.Group>
    </Wrapper>
  );
};

export default Index;
