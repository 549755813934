import React from 'react';
import { getIn, ErrorMessage } from 'formik';
import { Popup, Icon } from 'semantic-ui-react';
import { Wrapper, Output } from './styles';
import { Error } from 'components/Form';
import MandatoryFieldLabel from 'components/MandatoryFieldLabel';

const Index = ({
  placeholder,
  label,
  margin,
  labelPosition,
  labelVerticalPosition,
  bulleted,
  bullMsg,
  mandatory,
}: any) => {
  return (
    <Wrapper
      margin={margin}
      labelPosition={labelPosition}
      labelVerticalPosition={labelVerticalPosition}
    >
      {label && (
        <label>
          {label}
          {mandatory && <MandatoryFieldLabel />}
          {bulleted && (
            <Wrapper float='right'>
              <Popup
                trigger={<Icon name='info circle' />}
                content={bullMsg}
                size='mini'
              />
            </Wrapper>
          )}
        </label>
      )}

     <Output>{placeholder}</Output>
    </Wrapper>
  );
};

export default Index;
