import { resetState, refreshError } from 'store/common';
import actionTypes from './actionTypes';
import produce from 'immer';

const INITIAL_STATE = {
  success: {
    subscribe: null,
    subscribeWithCode: null,
    unsubscribe: null,
    getAll: null,
    getOne: null,
    getPODOfOneTransporter: null,
    getPODOfOneBuyer: null,
    getPODOfOneSeller: null,
    getPODOfOneSellerByFilters: null,
    create: null,
    getTotalVatRisk: null,
    getNumberOfReceived: null,
    getNumberOfPending: null,
    getNumberOfProofPending: null,
    uploadProof: null,
    downloadAllProof: null,
    downloadOneProof: null,
    getVatRisk: null,
    validateProof: null,
    refuseProof: null,
    validateDelivery: null,
    getOneForBuyer: null,
    getOneForTransporter: null,
    downloadAttestation: null,
    askForProof: null,
    contact: null,
    createMassIntegration: null,
    getMassIntegrations: null,
    getOneMassIntegration: null,
    deleteMassIntegration: null,
    downloadMassIntegrationTemplate: null,
    getCompanySubscribed: null,
    downloadCustomsRegimeInvoice: null,
    customsDeclarationIDs: null,
    getCDIDs: null,
    searchAllDeclId: null,
    getBuyersTransporters: null,
    getPrefilledDelivery: null,
    vat: null,
    checkQuickproofExist: null,
    hasPaidSubscription: null,
    update: null,
    splitProofs: null,
  },
  errors: {
    subscribe: null,
    subscribeWithCode: null,
    unsubscribe: null,
    getAll: null,
    getOne: null,
    getPODOfOneTransporter: null,
    getPODOfOneBuyer: null,
    getPODOfOneSeller: null,
    getPODOfOneSellerByFilters: null,
    create: null,
    getTotalVatRisk: null,
    getNumberOfReceived: null,
    getNumberOfPending: null,
    getNumberOfProofPending: null,
    uploadProof: null,
    downloadAllProof: null,
    downloadOneProof: null,
    getVatRisk: null,
    validateProof: null,
    refuseProof: null,
    validateDelivery: null,
    getOneForBuyer: null,
    getOneForTransporter: null,
    downloadAttestation: null,
    askForProof: null,
    contact: null,
    createMassIntegration: null,
    getMassIntegrations: null,
    getOneMassIntegration: null,
    deleteMassIntegration: null,
    downloadMassIntegrationTemplate: null,
    getCompanySubscribed: null,
    downloadCustomsRegimeInvoice: null,
    customsDeclarationIDs: null,
    searchAllDeclId: null,
    getCDIDs: null,
    getBuyersTransporters: null,
    getPrefilledDelivery: null,
    vat: null,
    checkQuickproofExist: null,
    hasPaidSubscription: null,
    update: null,
    splitProofs: null,
  },
  loading: {
    subscribe: false,
    subscribeWithCode: false,
    unsubscribe: false,
    getAll: false,
    getOne: false,
    getPODOfOneTransporter: false,
    getPODOfOneBuyer: false,
    getPODOfOneSeller: false,
    getPODOfOneSellerByFilters: false,
    create: false,
    getTotalVatRisk: false,
    getNumberOfReceived: false,
    getNumberOfPending: false,
    getNumberOfProofPending: false,
    uploadProof: false,
    downloadAllProof: false,
    downloadOneProof: false,
    getVatRisk: false,
    validateProof: false,
    refuseProof: false,
    validateDelivery: false,
    getOneForBuyer: false,
    getOneForTransporter: false,
    downloadAttestation: false,
    askForProof: false,
    contact: false,
    createMassIntegration: false,
    getMassIntegrations: false,
    getOneMassIntegration: false,
    deleteMassIntegration: false,
    downloadMassIntegrationTemplate: false,
    getCompanySubscribed: false,
    downloadCustomsRegimeInvoice: false,
    customsDeclarationIDs: false,
    searchAllDeclId: false,
    getCDIDs: false,
    getBuyersTransporters: false,
    getPrefilledDelivery: false,
    vat: false,
    checkQuickproofExist: false,
    hasPaidSubscription: false,
    update: false,
    splitProofs: false,
  },

  companySubscribed: null,
  allDeliveries: null,
  oneDelivery: null,
  podOfOneTransporter: null,
  podOfOneBuyer: null,
  podOfOneSeller: null,
  totalPodOfOneSeller: null,
  podOfOneSellerByFilters: null,
  totalVatRisk: null,
  numberOfReceived: null,
  numberOfPending: null,
  numberOfProofPending: null,
  vatRisk: null,
  oneProof: null,
  oneForBuyer: null,
  oneForTransporter: null,
  massIntegrations: null,
  totalMassIntegrations: null,
  massIntegration: null,
  customsDeclarationIDs: null,
  searchAllDeclId: null,
  getBuyersTransporters: null,
  getCDIDs: null,
  getPrefilledDelivery: null,
  vat: null,
  checkQuickproofExist: null,
  hasPaidSubscription: null,
  update: null,
  subscribe: null,
  splitProofs: null,
  error: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case actionTypes.UPLOAD_SPLIT_PROOF:
      return produce(state, (draft) => {
        draft.loading.splitProofs = true;
      });
    case actionTypes.UPLOAD_SPLIT_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.splitProofs = null;
        draft.loading.splitProofs = false;
        draft.success.splitProofs = true;
        draft.splitProofs = action.payload;
      });
    case actionTypes.UPLOAD_SPLIT_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.splitProofs = true;
        draft.loading.splitProofs = false;
        draft.success.splitProofs = false;
        !draft.error.includes('splitProofs') &&
          draft.error.push('splitProofs');
      });

    case actionTypes.HAS_PAID_SUBSCRIPTION:
      return produce(state, (draft) => {
        draft.loading.hasPaidSubscription = true;
      });
    case actionTypes.HAS_PAID_SUBSCRIPTION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.hasPaidSubscription = null;
        draft.loading.hasPaidSubscription = false;
        draft.success.hasPaidSubscription = true;
        draft.hasPaidSubscription = action.payload;
      });
    case actionTypes.HAS_PAID_SUBSCRIPTION_ERROR:
      return produce(state, (draft) => {
        draft.errors.hasPaidSubscription = true;
        draft.loading.hasPaidSubscription = false;
        draft.success.hasPaidSubscription = false;
        !draft.error.includes('hasPaidSubscription') &&
          draft.error.push('hasPaidSubscription');
      });
     
    case actionTypes.CHECK_QUICKPROOF_EXIST:
      return produce(state, (draft) => {
        draft.loading.checkQuickproofExist = true;
      });
    case actionTypes.CHECK_QUICKPROOF_EXIST_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.checkQuickproofExist = null;
        draft.loading.checkQuickproofExist = false;
        draft.success.checkQuickproofExist = true;
        draft.checkQuickproofExist = action.payload;
      });
    case actionTypes.CHECK_QUICKPROOF_EXIST_ERROR:
      return produce(state, (draft) => {
        draft.errors.checkQuickproofExist = true;
        draft.loading.checkQuickproofExist = false;
        draft.success.checkQuickproofExist = false;
        !draft.error.includes('checkQuickproofExist') &&
          draft.error.push('checkQuickproofExist');
      });

    
     case actionTypes.SET_VAT:
      return produce(state, (draft) => {
        draft.loading.vat = true;
      });
    case actionTypes.SET_VAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.vat = null;
        draft.loading.vat = false;
        draft.success.vat = true;
        draft.vat = action.payload;
      });
    case actionTypes.SET_VAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.vat = true;
        draft.loading.vat = false;
        draft.success.vat = false;
        !draft.error.includes('vat') &&
          draft.error.push('vat');
      });
      
    case actionTypes.GET_CUSTOM_DECLARATIONS_ID:
      return produce(state, (draft) => {
        draft.loading.getCDIDs = true;
      });
    case actionTypes.GET_CUSTOM_DECLARATIONS_ID_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCDIDs = null;
        draft.loading.getCDIDs = false;
        draft.success.getCDIDs = true;
        draft.getCDIDs = action.payload;
      });
    case actionTypes.GET_CUSTOM_DECLARATIONS_ID_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCDIDs = true;
        draft.loading.getCDIDs = false;
        draft.success.getCDIDs = false;
        !draft.error.includes('getCDIDs') &&
          draft.error.push('getCDIDs');
      });

    // getBuyersTransporters
    // case actionTypes.GET_FOR_ONE_ALL_BT:
    //   return produce(state, (draft) => {
    //     draft.loading.getBuyersTransporters = true;
    //   });
    // case actionTypes.GET_FOR_ONE_ALL_BT_SUCCESS:
    //   return produce(state, (draft) => {
    //     draft.errors.getBuyersTransporters = null;
    //     draft.loading.getBuyersTransporters = false;
    //     draft.success.getBuyersTransporters = true;
    //     draft.getBuyersTransporters = action.payload;
    //   });
    // case actionTypes.GET_FOR_ONE_ALL_BT_ERROR:
    //   return produce(state, (draft) => {
    //     draft.errors.getBuyersTransporters = true;
    //     draft.loading.getBuyersTransporters = false;
    //     draft.success.getBuyersTransporters = false;
    //     !draft.error.includes('getBuyersTransporters') &&
    //       draft.error.push('getBuyersTransporters');
    //   });

     //  getPrefilledDelivery
    case actionTypes.GET_PREFILLED:
      return produce(state, (draft) => {
        draft.loading.getPrefilledDelivery = true;
      });
    case actionTypes.GET_PREFILLED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPrefilledDelivery = null;
        draft.loading.getPrefilledDelivery = false;
        draft.success.getPrefilledDelivery = true;
        draft.getPrefilledDelivery = action.payload;
      });
    case actionTypes.GET_PREFILLED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPrefilledDelivery = true;
        draft.loading.getPrefilledDelivery = false;
        draft.success.getPrefilledDelivery = false;
        !draft.error.includes('getPrefilledDelivery') &&
          draft.error.push('getPrefilledDelivery');
      });

    case actionTypes.GET_FOR_ONE_ALL_BT:
      return produce(state, (draft) => {
        draft.loading.getBuyersTransporters = true;
      });
    case actionTypes.GET_FOR_ONE_ALL_BT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getBuyersTransporters = null;
        draft.loading.getBuyersTransporters = false;
        draft.success.getBuyersTransporters = true;
        draft.getBuyersTransporters = action.payload;
      });
    case actionTypes.GET_FOR_ONE_ALL_BT_ERROR:
      return produce(state, (draft) => {
        draft.errors.getBuyersTransporters = true;
        draft.loading.getBuyersTransporters = false;
        draft.success.getBuyersTransporters = false;
        !draft.error.includes('getBuyersTransporters') &&
          draft.error.push('getBuyersTransporters');
      });


       // getAllDeclarationID
    case actionTypes.GET_CLIENT_REF:
      return produce(state, (draft) => {
        draft.loading.searchAllDeclId = true;
      });
    case actionTypes.GET_CLIENT_REF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.searchAllDeclId = null;
        draft.loading.searchAllDeclId = false;
        draft.success.searchAllDeclId = true;
        draft.searchAllDeclId = action.payload;
      });
    case actionTypes.GET_CLIENT_REF_ERROR:
      return produce(state, (draft) => {
        draft.errors.searchAllDeclId = true;
        draft.loading.searchAllDeclId = false;
        draft.success.searchAllDeclId = false;
        !draft.error.includes('searchAllDeclId') &&
          draft.error.push('searchAllDeclId');
      });

    // getCompanySubscribed
    case actionTypes.GET_COMPANY_SUBSCRIBED:
      return produce(state, (draft) => {
        draft.loading.getCompanySubscribed = true;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = null;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = true;
        draft.companySubscribed = action.payload;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getCompanySubscribed = true;
        draft.loading.getCompanySubscribed = false;
        draft.success.getCompanySubscribed = false;
        !draft.error.includes('companySubscribed') &&
          draft.error.push('companySubscribed');
      });

    //Subscribe
    case actionTypes.SUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribe = null;
        draft.loading.subscribe = false;
        draft.success.subscribe = true;
        draft.subscribe = action.payload;
      });
    case actionTypes.SUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribe = true;
        draft.loading.subscribe = false;
        draft.success.subscribe = false;
      });

    //SubscribeWithCode
    case actionTypes.SUBSCRIBE_WITH_CODE_LOADING:
      return produce(state, (draft) => {
        draft.loading.subscribeWithCode = true;
      });
    case actionTypes.SUBSCRIBE_WITH_CODE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribeWithCode = null;
        draft.loading.subscribeWithCode = false;
        draft.success.subscribeWithCode = true;
      });
    case actionTypes.SUBSCRIBE_WITH_CODE_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribeWithCode = true;
        draft.loading.subscribeWithCode = false;
        draft.success.subscribeWithCode = false;
      });

    //Contact
    case actionTypes.CONTACT_LOADING:
      return produce(state, (draft) => {
        draft.loading.contact = true;
      });
    case actionTypes.CONTACT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.contact = null;
        draft.loading.contact = false;
        draft.success.contact = true;
      });
    case actionTypes.CONTACT_ERROR:
      return produce(state, (draft) => {
        draft.errors.contact = true;
        draft.loading.contact = false;
        draft.success.contact = false;
      });

    //Unsubscribe
    case actionTypes.UNSUBSCRIBE_LOADING:
      return produce(state, (draft) => {
        draft.loading.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = null;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = true;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = false;
      });

    // asdForProof
    case actionTypes.ASK_FOR_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.askForProof = true;
      });

    case actionTypes.ASK_FOR_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.askForProof = null;
        draft.loading.askForProof = false;
        draft.success.askForProof = true;
      });

    case actionTypes.ASK_FOR_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.askForProof = true;
        draft.loading.askForProof = false;
        draft.success.askForProof = false;
        !draft.error.includes('askForProof') && draft.error.push('askForProof');
      });

    // Declare
    case actionTypes.GET_ALL_LOADING:
      return produce(state, (draft) => {
        draft.loading.getAll = true;
      });

    case actionTypes.GET_ALL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getAll = null;
        draft.loading.getAll = false;
        draft.success.getAll = true;
        draft.allDeliveries = action.payload;
      });

    case actionTypes.GET_ALL_ERROR:
      return produce(state, (draft) => {
        draft.errors.getAll = true;
        draft.loading.getAll = false;
        draft.success.getAll = false;
        !draft.error.includes('allDeliveries') &&
          draft.error.push('allDeliveries');
      });

    case actionTypes.GET_ONE_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOne = true;
      });

    case actionTypes.GET_ONE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOne = null;
        draft.loading.getOne = false;
        draft.success.getOne = true;
        draft.oneDelivery = action.payload;
      });

    case actionTypes.GET_ONE_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOne = true;
        draft.loading.getOne = false;
        draft.success.getOne = false;
        !draft.error.includes('oneDelivery') && draft.error.push('oneDelivery');
      });

    //download attestation
    case actionTypes.DOWNLOAD_ATTESTATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadAttestation = true;
      });

    case actionTypes.DOWNLOAD_ATTESTATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadAttestation = null;
        draft.loading.downloadAttestation = false;
        draft.success.downloadAttestation = true;
      });

    case actionTypes.DOWNLOAD_ATTESTATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadAttestation = true;
        draft.loading.downloadAttestation = false;
        draft.success.downloadAttestation = false;
        !draft.error.includes('downloadAttestation') &&
          draft.error.push('downloadAttestation');
      });

    //download customs regime invoice
    case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadCustomsRegimeInvoice = true;
      });

    case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadCustomsRegimeInvoice = null;
        draft.loading.downloadCustomsRegimeInvoice = false;
        draft.success.downloadCustomsRegimeInvoice = true;
      });

    case actionTypes.DOWNLOAD_CUSTOMS_REGIME_INVOICE_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadCustomsRegimeInvoice = true;
        draft.loading.downloadCustomsRegimeInvoice = false;
        draft.success.downloadCustomsRegimeInvoice = false;
        !draft.error.includes('downloadCustomsRegimeInvoice') &&
          draft.error.push('downloadCustomsRegimeInvoice');
      });

    // GetDeclaration details
    case actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPODOfOneTransporter = true;
      });

    case actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneTransporter = null;
        draft.loading.getPODOfOneTransporter = false;
        draft.success.getPODOfOneTransporter = true;
        draft.podOfOneTransporter = action.payload;
      });

    case actionTypes.GET_KYANITE_OF_ONE_TRANSPORTER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneTransporter = true;
        draft.loading.getPODOfOneTransporter = false;
        draft.success.getPODOfOneTransporter = false;
        !draft.error.includes('podOfOneTransporter') &&
          draft.error.push('podOfOneTransporter');
      });

    // GetDeclaration statement
    case actionTypes.GET_KYANITE_OF_ONE_BUYER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPODOfOneBuyer = true;
      });

    case actionTypes.GET_KYANITE_OF_ONE_BUYER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneBuyer = null;
        draft.loading.getPODOfOneBuyer = false;
        draft.success.getPODOfOneBuyer = true;
        draft.podOfOneBuyer = action.payload;
      });

    case actionTypes.GET_KYANITE_OF_ONE_BUYER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneBuyer = true;
        draft.loading.getPODOfOneBuyer = false;
        draft.success.getPODOfOneBuyer = false;
        !draft.error.includes('podOfOneBuyer') &&
          draft.error.push('podOfOneBuyer');
      });

    // getPODOfOneSeller
    case actionTypes.GET_KYANITE_OF_ONE_SELLER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPODOfOneSeller = true;
      });

    case actionTypes.GET_KYANITE_OF_ONE_SELLER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneSeller = null;
        draft.loading.getPODOfOneSeller = false;
        draft.success.getPODOfOneSeller = true;
        draft.podOfOneSeller = action.payload;
      });

    case actionTypes.GET_KYANITE_OF_ONE_SELLER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneSeller = true;
        draft.loading.getPODOfOneSeller = false;
        draft.success.getPODOfOneSeller = false;
        !draft.error.includes('podOfOneSeller') &&
          draft.error.push('podOfOneSeller');
      });

    // getPODOfOneSellerByFilters
    case actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getPODOfOneSellerByFilters = true;
      });

    case actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneSellerByFilters = null;
        draft.loading.getPODOfOneSellerByFilters = false;
        draft.success.getPODOfOneSellerByFilters = true;
        draft.podOfOneSellerByFilters = action.payload;
      });

    case actionTypes.GET_KYANITE_OF_ONE_SELLER_BY_FILTERS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getPODOfOneSellerByFilters = true;
        draft.loading.getPODOfOneSellerByFilters = false;
        draft.success.getPODOfOneSellerByFilters = false;
        !draft.error.includes('podOfOneSellerByFilters') &&
          draft.error.push('podOfOneSellerByFilters');
      });

    // create
    case actionTypes.CREATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.create = true;
      });

    case actionTypes.CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.create = null;
        draft.loading.create = false;
        draft.success.create = true;
      });

    case actionTypes.CREATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.create = true;
        draft.loading.create = false;
        draft.success.create = false;
        !draft.error.includes('create') && draft.error.push('create');
      });

     // update
     case actionTypes.UPDATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.update = true;
      });

    case actionTypes.UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.update = null;
        draft.loading.update = false;
        draft.success.update = true;
      });

    case actionTypes.UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.update = true;
        draft.loading.update = false;
        draft.success.update = false;
        !draft.error.includes('update') && draft.error.push('update');
      });

    // getTotalVatRisk
    case actionTypes.GET_TOTAL_VAT_RISK_LOADING:
      return produce(state, (draft) => {
        draft.loading.getTotalVatRisk = true;
      });

    case actionTypes.GET_TOTAL_VAT_RISK_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getTotalVatRisk = null;
        draft.loading.getTotalVatRisk = false;
        draft.success.getTotalVatRisk = true;
        draft.totalVatRisk = action.payload;
      });

    case actionTypes.GET_TOTAL_VAT_RISK_ERROR:
      return produce(state, (draft) => {
        draft.errors.getTotalVatRisk = true;
        draft.loading.getTotalVatRisk = false;
        draft.success.getTotalVatRisk = false;
        !draft.error.includes('totalVatRisk') &&
          draft.error.push('totalVatRisk');
      });

    // getNumberOfReceived
    case actionTypes.GET_NUMBER_OF_RECEIVED_LOADING:
      return produce(state, (draft) => {
        draft.loading.getNumberOfReceived = true;
      });

    case actionTypes.GET_NUMBER_OF_RECEIVED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getNumberOfReceived = null;
        draft.loading.getNumberOfReceived = false;
        draft.success.getNumberOfReceived = true;
        draft.numberOfReceived = action.payload;
      });

    case actionTypes.GET_NUMBER_OF_RECEIVED_ERROR:
      return produce(state, (draft) => {
        draft.errors.getNumberOfReceived = true;
        draft.loading.getNumberOfReceived = false;
        draft.success.getNumberOfReceived = false;
        !draft.error.includes('numberOfReceived') &&
          draft.error.push('numberOfReceived');
      });

    // getNumberOfPending
    case actionTypes.GET_NUMBER_OF_PENDING_LOADING:
      return produce(state, (draft) => {
        draft.loading.getNumberOfPending = true;
      });

    case actionTypes.GET_NUMBER_OF_PENDING_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getNumberOfPending = null;
        draft.loading.getNumberOfPending = false;
        draft.success.getNumberOfPending = true;
        draft.numberOfPending = action.payload;
      });

    case actionTypes.GET_NUMBER_OF_PENDING_ERROR:
      return produce(state, (draft) => {
        draft.errors.getNumberOfPending = true;
        draft.loading.getNumberOfPending = false;
        draft.success.getNumberOfPending = false;
        !draft.error.includes('numberOfPending') &&
          draft.error.push('numberOfPending');
      });

    // getNumberOfProofPending
    case actionTypes.GET_NUMBER_OF_PROOF_PENDING_LOADING:
      return produce(state, (draft) => {
        draft.loading.getNumberOfProofPending = true;
      });

    case actionTypes.GET_NUMBER_OF_PROOF_PENDING_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getNumberOfProofPending = null;
        draft.loading.getNumberOfProofPending = false;
        draft.success.getNumberOfProofPending = true;
        draft.numberOfProofPending = action.payload;
      });

    case actionTypes.GET_NUMBER_OF_PROOF_PENDING_ERROR:
      return produce(state, (draft) => {
        draft.errors.getNumberOfProofPending = true;
        draft.loading.getNumberOfProofPending = false;
        draft.success.getNumberOfProofPending = false;
        !draft.error.includes('numberOfProofPending') &&
          draft.error.push('numberOfProofPending');
      });

    // uploadProof
    case actionTypes.UPLOAD_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.uploadProof = true;
      });

    case actionTypes.UPLOAD_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.uploadProof = null;
        draft.loading.uploadProof = false;
        draft.success.uploadProof = true;
      });

    case actionTypes.UPLOAD_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.uploadProof = true;
        draft.loading.uploadProof = false;
        draft.success.uploadProof = false;
        !draft.error.includes('uploadProof') && draft.error.push('uploadProof');
      });

    // downloadAllProof
    case actionTypes.DOWNLOAD_ALL_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadAllProof = true;
      });

    case actionTypes.DOWNLOAD_ALL_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadAllProof = null;
        draft.loading.downloadAllProof = false;
        draft.success.downloadAllProof = true;
      });

    case actionTypes.DOWNLOAD_ALL_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadAllProof = true;
        draft.loading.downloadAllProof = false;
        draft.success.downloadAllProof = false;
        !draft.error.includes('downloadAllProof') &&
          draft.error.push('downloadAllProof');
      });

    // downloadOneProof
    case actionTypes.DOWNLOAD_ONE_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadOneProof = true;
      });

    case actionTypes.DOWNLOAD_ONE_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadOneProof = null;
        draft.loading.downloadOneProof = false;
        draft.success.downloadOneProof = true;
        draft.oneProof = action.payload;
      });

    case actionTypes.DOWNLOAD_ONE_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadOneProof = true;
        draft.loading.downloadOneProof = false;
        draft.success.downloadOneProof = false;
        !draft.error.includes('oneProof') && draft.error.push('oneProof');
      });

    case actionTypes.GET_VAT_RISK_LOADING:
      return produce(state, (draft) => {
        draft.loading.getVatRisk = true;
      });

    case actionTypes.GET_VAT_RISK_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getVatRisk = null;
        draft.loading.getVatRisk = false;
        draft.success.getVatRisk = true;
        draft.vatRisk = action.payload;
      });

    case actionTypes.GET_VAT_RISK_ERROR:
      return produce(state, (draft) => {
        draft.errors.getVatRisk = true;
        draft.loading.getVatRisk = false;
        draft.success.getVatRisk = false;
        !draft.error.includes('vatRisk') && draft.error.push('vatRisk');
      });

    case actionTypes.VALIDATE_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.validateProof = true;
      });

    case actionTypes.VALIDATE_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.validateProof = null;
        draft.loading.validateProof = false;
        draft.success.validateProof = true;
      });

    case actionTypes.VALIDATE_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.validateProof = true;
        draft.loading.validateProof = false;
        draft.success.validateProof = false;
        !draft.error.includes('validateProof') &&
          draft.error.push('validateProof');
      });

    case actionTypes.REFUSE_PROOF_LOADING:
      return produce(state, (draft) => {
        draft.loading.refuseProof = true;
      });

    case actionTypes.REFUSE_PROOF_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.refuseProof = null;
        draft.loading.refuseProof = false;
        draft.success.refuseProof = true;
      });

    case actionTypes.REFUSE_PROOF_ERROR:
      return produce(state, (draft) => {
        draft.errors.refuseProof = true;
        draft.loading.refuseProof = false;
        draft.success.refuseProof = false;
        !draft.error.includes('refuseProof') && draft.error.push('refuseProof');
      });

    case actionTypes.VALIDATE_DELIVERY_LOADING:
      return produce(state, (draft) => {
        draft.loading.validateDelivery = true;
      });

    case actionTypes.VALIDATE_DELIVERY_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.validateDelivery = null;
        draft.loading.validateDelivery = false;
        draft.success.validateDelivery = true;
      });

    case actionTypes.VALIDATE_DELIVERY_ERROR:
      return produce(state, (draft) => {
        draft.errors.validateDelivery = true;
        draft.loading.validateDelivery = false;
        draft.success.validateDelivery = false;
        !draft.error.includes('validateDelivery') &&
          draft.error.push('validateDelivery');
      });

    case actionTypes.GET_ONE_FOR_BUYER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneForBuyer = true;
      });

    case actionTypes.GET_ONE_FOR_BUYER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneForBuyer = null;
        draft.loading.getOneForBuyer = false;
        draft.success.getOneForBuyer = true;
        draft.oneForBuyer = action.payload;
      });

    case actionTypes.GET_ONE_FOR_BUYER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneForBuyer = true;
        draft.loading.getOneForBuyer = false;
        draft.success.getOneForBuyer = false;
        !draft.error.includes('oneForBuyer') && draft.error.push('oneForBuyer');
      });

    case actionTypes.GET_ONE_FOR_TRANSPORTER_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneForTransporter = true;
      });

    case actionTypes.GET_ONE_FOR_TRANSPORTER_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneForTransporter = null;
        draft.loading.getOneForTransporter = false;
        draft.success.getOneForTransporter = true;
        draft.oneForTransporter = action.payload;
      });

    case actionTypes.GET_ONE_FOR_TRANSPORTER_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneForTransporter = true;
        draft.loading.getOneForTransporter = false;
        draft.success.getOneForTransporter = false;
        !draft.error.includes('oneForTransporter') &&
          draft.error.push('oneForTransporter');
      });

    //create mass integration
    case actionTypes.CREATE_MASSINTEGRATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.createMassIntegration = true;
      });
    case actionTypes.CREATE_MASSINTEGRATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createMassIntegration = null;
        draft.loading.createMassIntegration = false;
        draft.success.createMassIntegration = true;
      });
    case actionTypes.CREATE_MASSINTEGRATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.createMassIntegration = true;
        draft.loading.createMassIntegration = false;
        draft.success.createMassIntegration = false;
        !draft.error.includes('oneForTransporter') &&
          draft.error.push('oneForTransporter');
      });

    //download attestation
    case actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE_LOADING:
      return produce(state, (draft) => {
        draft.loading.downloadMassIntegrationTemplate = true;
      });

    case actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadMassIntegrationTemplate = null;
        draft.loading.downloadMassIntegrationTemplate = false;
        draft.success.downloadMassIntegrationTemplate = true;
      });

    case actionTypes.DOWNLOAD_MASSINTEGRATION_TEMPLATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadMassIntegrationTemplate = true;
        draft.loading.downloadMassIntegrationTemplate = false;
        draft.success.downloadMassIntegrationTemplate = false;
        !draft.error.includes('downloadMassIntegrationTemplate') &&
          draft.error.push('downloadMassIntegrationTemplate');
      });

    //get mass integration
    case actionTypes.GET_MASSINTEGRATIONS_LOADING:
      return produce(state, (draft) => {
        draft.loading.getMassIntegrations = true;
      });
    case actionTypes.GET_MASSINTEGRATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getMassIntegrations = null;
        draft.loading.getMassIntegrations = false;
        draft.success.getMassIntegrations = true;
        draft.massIntegrations = action.payload.rows;
        draft.totalMassIntegrations = action.payload.count;
      });
    case actionTypes.GET_MASSINTEGRATIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.getMassIntegrations = true;
        draft.loading.getMassIntegrations = false;
        draft.success.getMassIntegrations = false;
        !draft.error.includes('massIntegrations') &&
          draft.error.push('massIntegrations');
      });

    //get one mass integration
    case actionTypes.GET_ONE_MASSINTEGRATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.getOneMassIntegration = true;
      });
    case actionTypes.GET_ONE_MASSINTEGRATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.getOneMassIntegration = null;
        draft.loading.getOneMassIntegration = false;
        draft.success.getOneMassIntegration = true;
        draft.massIntegration = action.payload;
      });
    case actionTypes.GET_ONE_MASSINTEGRATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.getOneMassIntegration = true;
        draft.loading.getOneMassIntegration = false;
        draft.success.getOneMassIntegration = false;
        !draft.error.includes('massIntegration') &&
          draft.error.push('massIntegration');
      });

    //delete mass integration
    case actionTypes.DELETE_MASSINTEGRATION_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteMassIntegration = true;
      });
    case actionTypes.DELETE_MASSINTEGRATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteMassIntegration = null;
        draft.loading.deleteMassIntegration = false;
        draft.success.deleteMassIntegration = true;
      });
    case actionTypes.DELETE_MASSINTEGRATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteMassIntegration = true;
        draft.loading.deleteMassIntegration = false;
        draft.success.deleteMassIntegration = false;
      });


    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    case actionTypes.REFRESH_ERROR:
      return refreshError(state.error, action.payload.keys);

    default:
      return state;
  }
};
