import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Icon } from 'semantic-ui-react';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { FiscalPeriod } from 'components/Form';
import { monitoringFiltersSchema } from 'interfaces/formSchema/intrastatValidators';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';
import { VertSep } from './styles';

const SearchFilters = ({
  dispatch,
  applyFilters,
  searchLoading,
  resetFilters,
  isVisible,
  companies
}: any) => {
  const [companiesList, setcompaniesList] = useState([]);
  const searchRef = useRef()

  type session = {
    intrastatId: number,
    Company_Id: string,
    declarationType: string,
    flow: string,
    intranetReference: string,
    filingPeriod: string,
    showAll: boolean,
  }

  const data: session = JSON.parse(sessionStorage.getItem('intra_search'))

  useEffect(() => {
    if (isVisible && companies) {
      setcompaniesList(
        companies.map((company, index) => {
          return {
            key: index,
            value: company.id,
            flag: company.country.toLowerCase(),
            text: company.name,
          };
        })
      );
    }
 
  }, [companies, isVisible]);

  return (
    <Segment
      {...(!isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        ref={searchRef}
        enableReinitialize={true} 
        validateOnChange={true}
        initialValues={data ? {
          intrastatId: data.intrastatId || '',
          companyId: data.Company_Id || null,
          flow: data.flow || '',
          declarationType: data.declarationType || null,
          intranetReference: data.intranetReference || '',
          filingPeriod: data.filingPeriod || '',
          showAll: data.showAll ? data.showAll : false,
        } : {
              intrastatId: '',
              companyId: '',
              declarationType: '',
              flow: '',
              intranetReference: '',
              filingPeriod: '',
              showAll: false,
            }}
        onSubmit={applyFilters}
        validationSchema={monitoringFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
             
              <Wrapper 
                display='flex' 
                margin='0.5rem' 
                justifyContent='center'
              >
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Intrastat reference...'
                    name='intrastatId'
                    id='intrastatId'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>         
                <Wrapper margin='0.5rem' width='220px'>
                  <Field
                    placeholder='Company...'
                    name='companyId'
                    id='companyId'
                    options={companiesList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper> 
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Type...'
                    name='declarationType'
                    id='declarationType'
                    options={[
                      {
                        key: 0,
                        value: 'DEB',
                        text: 'DEB'
                      },
                      {
                        key: 1,
                        value: 'DES',
                        text: 'DES'
                      },
                    ]}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>                                                    
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Flow...'
                    name='flow'
                    id='flow'
                    options={[
                      {
                        key: 0,
                        value: 'expedition',
                        text: 'Expedition'
                      },
                      {
                        key: 1,
                        value: 'introduction',
                        text: 'Introduction'
                      },
                    ]}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>                                                   
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Intranet reference'
                    name='intranetReference'
                    id='intranetReference'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='filingPeriod'
                    name='filingPeriod'
                    id='filingPeriod'
                    component={FiscalPeriod}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
            <Wrapper display='flex' margin='1rem' justifyContent='center' alignItems='center'>
                <Field              
                  name='showAll'
                  id='showAll'
                  label='Archives'
                  margin='20px'
                  checked={values.showAll}
                  component={Checkbox}
                  meta={{ 
                    errors, 
                    touched,
                    setFieldValue, 
                    setFieldTouched 
                  }}
                  onChange={() => {
                    setFieldValue('showAll', !values.showAll)
                  }}
                />
              <VertSep />
              <Button
                disabled={searchLoading}
                {...(searchLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetFilters();
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(SearchFilters);
