import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Icon, Table, Header } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';

import Loader from 'components/Loader';
import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/customs/declaration';
import Pagination from 'components/Pagination';
import { meanTime, shortDate } from 'services/helper/dateFormater';
import {
  customsDeclarationSteps,
  customsDeclarationStepColors,
} from 'constants/customsDeclarationCategories';
import { Step, Spacer } from 'components/core';
import ModalMonitoringTutorial from './Components/ModalMonitoringTutorial/ModalMonitoringTutorial';
import ModalConvertFile from './Components/ModalConvertFile/ModalConvertFile';
import SearchFilters from './Components/SearchFilters/searchFilters';
import FollowersList from '../../Components/FollowersList/FollowersList';
import DownloadFilesModal from 'views/Customs/Declaration/Service/DownloadFilesModal/downloadFilesModal';
import styled from '@emotion/styled';
import { formatPrice } from 'services/helper/priceFormater';



const CustomsDeclarationMonitoring = ({
  dispatch,
  history,
  match,
  user,
  processingDeclarations,
  processingDeclarationsByFilters,
  processingDeclarationsCount,
  declarationsInvoice,
  declarationsInvoiceByFilters,
  declarationsInvoiceCount,
  convertFileLoading,
  extractLoading,
}) => {

  const limitPerPage = 50;
  const [filter, setFilter] = useState('all');
  const [currentUser, setUser] = useState(null);
  const [searchFilters, setsearchFilters] = useState(null);
  const [loading, setLoading] = useState(true);
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterBar, setfilterBar] = useState(false);
  const [resetPagination, setresetPagination] = useState(false);
  const [modalConvertFile, setmodalConvertFile] = useState(false);
  const [downloadFilesModal, setdownloadFilesModal] = useState(false);
  const storedSearch = useMemo(() => JSON.parse(sessionStorage.getItem('customs_search')), [])
  let storedPage = sessionStorage.getItem('customs_page') !== undefined ? JSON.parse(sessionStorage.getItem('customs_page')) : null

  const typeBae = true

  useEffect(() => {
    return () => {
      dispatch(
        actions.customsDeclaration.reset([
          'processingDeclarations',
          'processingDeclarationsByFilters',
          'processingDeclarationsCount',
          'transitRisk',
          'error',
          'declarationsInvoice',
          'declarationsInvoiceByFilters',
          'declarationsInvoiceCount',
        ])
      );
      dispatch(actions.socket.unsubscribeUpdateCustomsDeclarationProcessing());
      dispatch(actions.socket.unsubscribeDeclarationsInvoice());
    };
  }, [dispatch]);

  useEffect(() => {
  
    if(filter !== 'invoiced' && processingDeclarations === null) {
      setLoading(true);
      dispatch(
        actions.customsDeclaration.reset([
          'error',
          'declarationsInvoice',
          'declarationsInvoiceCount',
        ])
      );

      if(searchFilters) {
        sessionStorage.setItem('customs_search', JSON.stringify(searchFilters));
        sessionStorage.setItem('customs_page', storedPage ? storedPage : 1)
        dispatch(
          actions.customsDeclaration.getProcessingDeclarationsByFilters({
            data: searchFilters,
            query: {
              limit: limitPerPage,
              page: 1,
            },
          })
        );
      }else{
        sessionStorage.setItem('customs_page', storedPage ? storedPage : 1)
        dispatch(
          actions.customsDeclaration.getProcessingDeclarations({
            query: {
              limit: limitPerPage,
              page: 1,
            },
          })
        );
        dispatch(actions.socket.subscribeUpdateCustomsDeclarationProcessing());
      }

      dispatch(actions.socket.unsubscribeDeclarationsInvoice());
    }

      if(filter === 'invoiced' && declarationsInvoice === null){
        setLoading(true);
        dispatch(
          actions.customsDeclaration.reset([
            'error',
            'processingDeclarations',
            'processingDeclarationsCount',
          ])
      );
      if(searchFilters){
        dispatch(
          actions.customsDeclaration.getDeclarationsInvoiceByFilters({
            data: searchFilters,
            query: {
              limit: limitPerPage,
              page: 1,
            },
          })
        );
      }else{
        dispatch(
          actions.customsDeclaration.getDeclarationsInvoice({
            query: {
              limit: limitPerPage,
              page: 1,
            },
          })
        );
        dispatch(actions.socket.subscribeDeclarationsInvoice());
      }

      dispatch(actions.socket.unsubscribeUpdateCustomsDeclarationProcessing());
    }
    setresetPagination(true);
  }, [
    dispatch, 
    filter, 
    searchFilters, 
    storedSearch, 
    storedPage, 
    declarationsInvoice, 
    processingDeclarations
  ]);



  const invoicedFilter = (declarations) => {
    let tabLength = [], tab = [] 
    declarations.forEach((d, index) => {

      for(const declaration of declarations[index].CustomsDeclarationCategories){
        if(declaration.step === 3){
          tabLength.push('a')
        }
      }

      if(tabLength.length === declarations[index].length){
        tab.push(d)
      }

    })

    return tab
  }


  useEffect(() => {
    let declarations

    if(currentUser === 'moi'){
      if(filter === 'all'){
        declarations = (processingDeclarationsByFilters || processingDeclarations).filter(
          (d) => { 
            return d.Users.some(
              (u) => u.CustomsDeclarationHasUser.User_Id === user.id
            )
          }
        )
      }else if(filter === 'invoiced'){
          declarations = (declarationsInvoiceByFilters || declarationsInvoice).filter(
            (d) => { 
              return d.Users.some(
                (u) => u.CustomsDeclarationHasUser.User_Id === user.id

              )
            }
          )
          declarations = invoicedFilter(declarations)
  
      }else{
        declarations = (processingDeclarationsByFilters || processingDeclarations).filter(
          (e) => {
            return e.CustomsDeclarationCategories.some(
              (a) => a.type === filter
            );
          }
        ).filter(
          (d) => { 
            return d.Users.some(
              (u) => u.CustomsDeclarationHasUser.User_Id === user.id
            )
          }
        )
      }
      setdata(declarations);
      setLoading(false); 
  }else{
    if(
      (filter === 'invoiced' &&
        (declarationsInvoice || declarationsInvoiceByFilters)) ||
      (filter !== 'invoiced' &&
        (processingDeclarations || processingDeclarationsByFilters))
    ){
     declarations =
        filter === 'all'
          ? processingDeclarationsByFilters || processingDeclarations
          : filter === 'invoiced'
          ? declarationsInvoiceByFilters || declarationsInvoice
          : (processingDeclarationsByFilters || processingDeclarations).filter(
              (e) => {
                return e.CustomsDeclarationCategories.some(
                  (a) => a.type === filter
                );
              }
            );
      setdata(declarations);
      setLoading(false);
    }
  }
  }, [
    filter,
    currentUser,
    declarationsInvoice,
    declarationsInvoiceByFilters,
    processingDeclarations,
    processingDeclarationsByFilters,
    user
  ]);

 
  useEffect(() => {
    if (data) {
      setresetPagination(false);
    }
  }, [data]);


  const getDataBySearchFilter = (newSearchFilters) => {
    if (!_.isEqual(searchFilters, newSearchFilters)) {
      dispatch(
        actions.customsDeclaration.reset([
          'processingDeclarationsByFilters',
          'declarationsInvoiceByFilters',
          'processingDeclarations',
          'processingDeclarationsCount',
          'error',
          'declarationsInvoice',
          'declarationsInvoiceCount',
        ])
      );

      setsearchFilters(newSearchFilters);
    }
  };

  const resetSearchFilters = () => {
    if (searchFilters) {
      sessionStorage.removeItem('customs_search');
      setsearchFilters(null);
      dispatch(
        actions.customsDeclaration.reset([
          'processingDeclarationsByFilters',
          'declarationsInvoiceByFilters',
          'processingDeclarations',
          'processingDeclarationsCount',
          'error',
          'declarationsInvoice',
          'declarationsInvoiceCount',
        ])
      );
    }
  };

  const handleSort = (name) => {
    if (column !== name) {
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const extractDocuments = (data) => {
    dispatch(actions.customsDeclaration.generateBaeExtract({
      params: {
        from: data.startDate,
        to: data.endDate,
      },
      data: {
        withDocuments: true,
        regime42: true,
        attributes: [
          'id',
          'createdAt',
          'state'
        ]
      },
    }))

    setdownloadFilesModal(false)
    
    return () => {
      dispatch(actions.customsDeclaration.reset(['error', 'baeExtract']))  
    }
  }

  const handlePageChange = (event, data) => {
    if (filter === 'invoiced') {
      sessionStorage.setItem('customPage', Math.ceil(data.activePage).toString())
      if (declarationsInvoiceByFilters) {
        dispatch(
          actions.customsDeclaration.getDeclarationsInvoiceByFilters({
            data: searchFilters,
            query: {
              limit: limitPerPage,
              page: Math.ceil(data.activePage),
            },
          })
        );
      } else {
        dispatch(
          actions.customsDeclaration.getDeclarationsInvoice({
            query: {
              limit: limitPerPage,
              page: Math.ceil(data.activePage),
            },
          })
        );
      }
    } else {
      sessionStorage.setItem('customPage', Math.ceil(data.activePage).toString())
      if (processingDeclarationsByFilters) {
        dispatch(
          actions.customsDeclaration.getProcessingDeclarationsByFilters({
            data: searchFilters,
            query: {
              limit: limitPerPage,
              page: Math.ceil(data.activePage),
            },
          })
        );
      } else {
        dispatch(
          actions.customsDeclaration.getProcessingDeclarations({
            query: {
              limit: limitPerPage,
              page: Math.ceil(data.activePage),
            },
          })
        );
      }
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const Monitoring = styled.div`
    float: right;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-item: center;
    margin: 0;
    padding: 2px 5px;
    background: white;
    font-size: 11px;
    font-weight: bold;
    border-radius: 20px;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
  `



  return (
    <Wrapper overflow='auto' width='90%' margin='auto'>
      {modalConvertFile && (
        <ModalConvertFile
          open={modalConvertFile}
          handleClose={() => setmodalConvertFile(false)}
          dispatch={dispatch}
        />
      )}
      {downloadFilesModal && (
        <DownloadFilesModal
          open={downloadFilesModal}
          onMount={typeBae}
          handleClose={() => setdownloadFilesModal(false)}
          match={match}
          extractDocuments={extractDocuments}
        />
      )}
      <Divider hidden />
      <Wrapper display='flex'>
        <ButtonBack history={history} />
      </Wrapper>
      <ModalMonitoringTutorial
        isModalOpen={isModalOpen}
        handleClose={handleClose}
      />
      <Divider hidden />
      {((loading || convertFileLoading) && <Loader content='Loading' />) || (
        <Wrapper>
          <Wrapper display='flex' justifyContent='center'>
            <Header size='huge'>Customs declaration Monitoring</Header>
          </Wrapper>
          <Spacer height='30px' />
          <Wrapper display='flex' justifyContent='space-between'>
            <p>Number of declarations displayed with filters: {data.length}</p>
            <p>
              Total number of reports in progress:{' '}
              {processingDeclarationsCount || declarationsInvoiceCount}
            </p>
          </Wrapper>
          <Wrapper display='flex' justifyContent='space-between'>
            <Wrapper display='flex' justifyContent='space-between'>
              <Button
                icon='filter'
                type='button'
                content='Search Filters'
                color='blue'
                onClick={() => setfilterBar(!filterBar)}
              />
              <Button
                type='button'
                content='Tutorial'
                color='blue'
                onClick={() => setIsModalOpen(true)}
              />
              <Button
                type='button'
                content='Convert file'
                color='blue'
                onClick={() => setmodalConvertFile(true)}
              />      
              <Button
                disabled={extractLoading}
                {...(extractLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='button'
                content='Extract regime 42 DAU BAE'
                color='blue'
                onClick={() => setdownloadFilesModal(true)}
              /> 
            </Wrapper>
           
            <Wrapper display='flex' justifyContent='center'>
              <Button
                type='button'
                content='All'
                color={filter === 'all' ? 'activeBlue' : 'blue'}
                onClick={() => {
                  setFilter('all')
                  setUser(null)
                }}
              />
               <Button
                type='button'
                icon='user' 
                color={currentUser === 'moi' ? 'activeBlue' : 'blue'}
                onClick={() => setUser('moi')}
              />
              <Button
                type='button'
                content='Accises'
                color={filter === 'accises' ? 'activeBlue' : 'blue'}
                onClick={() => setFilter('accises')}
              />
              <Button
                type='button'
                content='Transits'
                color={filter === 'transit' ? 'activeBlue' : 'blue'}
                onClick={() => setFilter('transit')}
              />
              <Button
                type='button'
                content='Customs clearance'
                color={filter === 'customsClearance' ? 'activeBlue' : 'blue'}
                onClick={() => setFilter('customsClearance')}
              />
              <Button
                type='button'
                content='Traces'
                color={filter === 'traces' ? 'activeBlue' : 'blue'}
                onClick={() => setFilter('traces')}
              />
              <Button
                type='button'
                content='Customs clerk'
                color={filter === 'customsClerk' ? 'activeBlue' : 'blue'}
                onClick={() => setFilter('customsClerk')}
              />
              <Button
                type='button'
                content='Invoicing'
                color={filter === 'invoiced' ? 'activeBlue' : 'blue'}
                onClick={() => setFilter('invoiced')}
              />
            </Wrapper>
          </Wrapper>

          <Wrapper display='flex' justifyContent='flex-end'>
            <SearchFilters
              isVisible={filterBar}
              applyFilters={getDataBySearchFilter}
              resetFilters={resetSearchFilters}
            />
          </Wrapper>
          <Table columns={10} stackable sortable selectable striped compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.HeaderCell>Intranet</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'company' ? direction : null}
                  onClick={() => handleSort('company')}
                >
                  Company
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'date' ? direction : 'descending'}
                  onClick={() => handleSort('date')}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell>Trailer license plate</Table.HeaderCell>
                <Table.HeaderCell>Truck license plate</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'customsOffice' ? direction : null}
                  onClick={() => handleSort('customsOffice')}
                >
                  Customs office
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    column === 'estimatedDateTimeArrival' ? direction : null
                  }
                  onClick={() => handleSort('estimatedDateTimeArrival')}
                >
                  Estimated arrival
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === 'followers' ? direction : null}
                  onClick={() => handleSort('followers')}
                >
                  Followers
                </Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(data.length === 0 && <EmptyTable colNumber={10} />) ||
                data.map((declaration, i) => {
                  let accises = data[i].CustomsDeclarationCategories.length>0 && data[i].CustomsDeclarationCategories[0].name === 'accises'
                  let transit = data[i].CustomsDeclarationCategories.length>0 && data[i].CustomsDeclarationCategories[0].name === 'transit'
                  return (
                    <Table.Row key={i} warning={declaration.operatorNewMessage}>
                      <Link
                        to={paths.customsDeclarationDetails
                          .replace(':companyId', declaration.Company_Id)
                          .replace(':declarationId', declaration.id)}
                        className='table-link'
                      >                    
                        <Table.Cell>
                          <Wrapper
                            display='flex'
                            justifyContent='space-between'
                          >
                            {declaration.id}
                            {declaration.operatorNewMessage && (
                              <Icon name='envelope' color='red' size='large' />
                            )}
                          </Wrapper>
                        </Table.Cell>
                        <Table.Cell>{declaration.intranetReference}</Table.Cell>
                        <Table.Cell>{declaration.Company.name} {declaration.CustomsDeclarationUsers.map((e, i) => (
                          <div key={i} style={{'fontSize': '10px'}}>- {e.Company.name}</div>
                        ))}</Table.Cell>
                        <Table.Cell colSpan={(accises || transit) ? '5' : '0'}>
                          {shortDate(new Date(declaration.createdAt))}
                          {transit && declaration.Company.Monitoring.currentCumulative && (                                            
                              <Monitoring>              
                              <div>Period : {formatPrice(declaration.Company.Monitoring.threshold)}</div>
                              <div>-</div>
                              <div style={{'color': (declaration.Company.Monitoring.threshold <= declaration.Company.Monitoring.currentCumulative && 'red') || 'green'}}>
                              {formatPrice(declaration.Company.Monitoring.currentCumulative)}
                              </div>
                              </Monitoring>
                            )}
                        </Table.Cell>
                        {(!accises && !transit) && (
                        <>
                          <Table.Cell>
                            {declaration.trailerLicensePlate}
                          </Table.Cell>
                          <Table.Cell>
                            {declaration.truckLicensePlate}
                          </Table.Cell>
                          <Table.Cell>
                            {declaration.CustomsOffice && declaration.CustomsOffice.name}
                          </Table.Cell>                                
                        <Table.Cell>
                          {declaration.estimatedDateTimeArrival &&
                           meanTime(new Date(declaration.estimatedDateTimeArrival))}
                        </Table.Cell>
                        </>
                        )}            
                        <Table.Cell>
                          {declaration.Users && (
                            <FollowersList
                              users={declaration.Users}
                              visibleNumber={2}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {filter !== 'invoiced' &&
                            declaration &&
                            (processingDeclarations ||
                              processingDeclarationsByFilters) &&
                            !loading && (
                              <>
                                {declaration.CustomsDeclarationCategories?.map(
                                  (category, i) => (
                                    <Step
                                      key={i}
                                      label={category.description}
                                      popupContent={
                                        customsDeclarationSteps[
                                          category
                                            .CustomsDeclarationHasCategories
                                            .step - 1
                                        ]
                                      }
                                      color={
                                        customsDeclarationStepColors[
                                          category
                                            .CustomsDeclarationHasCategories
                                            .step - 1
                                        ]
                                      }
                                    />
                                  )
                                )}
                              </>
                            )}
                          {filter === 'invoiced' &&
                            declaration &&
                            (declarationsInvoice ||
                              declarationsInvoiceByFilters) &&
                            !loading && (
                              <>
                              <Step
                                label={
                                  declaration.invoiced
                                    ? 'Invoiced'
                                    : 'Not invoiced'
                                }
                                popupContent={
                                  declaration.invoiced
                                    ? 'Invoiced'
                                    : 'Not invoiced'
                                }
                                color={
                                  declaration.invoiced ? 'green' : '#cd2121'
                                }
                              />
                              {declaration.CustomsDeclarationCategories?.map(
                                  (category, i) => (
                                    <Step
                                      key={i}
                                      label={category.description}
                                      popupContent={
                                        customsDeclarationSteps[
                                          category
                                            .CustomsDeclarationHasCategories
                                            .step - 1
                                        ]
                                      }
                                      color={
                                        customsDeclarationStepColors[
                                          category
                                            .CustomsDeclarationHasCategories
                                            .step - 1
                                        ]
                                      }
                                    />
                                  )
                                )}
                                </>
                            )}
                        </Table.Cell>
                     
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
      )}

      <Divider hidden />

      {!resetPagination && 
      ((processingDeclarationsCount || declarationsInvoiceCount)> 0 && 
      (processingDeclarationsCount || declarationsInvoiceCount) > limitPerPage) &&
      (
        <Wrapper display='flex' justifyContent='center'>
        <Pagination
          totalPages={(processingDeclarationsCount || declarationsInvoiceCount)/limitPerPage}
          storedPage={sessionStorage.getItem('customPage') !== null ? parseInt(sessionStorage.getItem('customPage')) : 1}
          onChange={handlePageChange}
          resetPagination={resetPagination}
        />
        </Wrapper>
      )}   
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    processingDeclarations: selectors.customsDeclaration.processingDeclarationsSelector(
      state
    ),
    processingDeclarationsByFilters: selectors.customsDeclaration.processingDeclarationsByFiltersSelector(
      state
    ),
    processingDeclarationsCount: selectors.customsDeclaration.processingDeclarationsCountSelector(
      state
    ),
    declarationsInvoice: selectors.customsDeclaration.declarationsInvoiceSelector(
      state
    ),
    declarationsInvoiceByFilters: selectors.customsDeclaration.declarationsInvoiceByFiltersSelector(
      state
    ),
    declarationsInvoiceCount: selectors.customsDeclaration.declarationsInvoiceCountSelector(
      state
    ),
    convertFileLoading: selectors.customsDeclaration.convertFileLoadingSelector(
      state
    ),
    extractLoading: selectors.customsDeclaration.genBaeExtractLoadingsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(CustomsDeclarationMonitoring);
