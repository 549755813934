import React from "react";
import { getIn, ErrorMessage } from "formik";
import { DateInput } from "semantic-ui-calendar-react";

import { Wrapper } from "./styles";
import { Error } from "components/Form";

const Index = ({ meta, placeholder, field, label, margin }: any) => {
  return (
    <Wrapper margin={margin}>
      <label>{label}</label>
      <DateInput
        closable
        closeOnMouseLeave
        placeholder={placeholder}
        startMode="year"
        dateFormat="YYYY-MM-DD"
        name={field.name}
        onChange={(e, { name, value }) => {
          meta.setFieldValue(name, value);
        }}
        onBlur={field.onBlur}
        value={field.value}
        error={
          getIn(meta.touched, field.name) && getIn(meta.errors, field.name)
            ? true
            : false
        }
      />

      <ErrorMessage name={field.name} render={(msg) => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Index;
