import { resetState } from "store/common";
import actionTypes from "./actionTypes";
import produce from "immer";

const INITIAL_STATE = {
  success: {
    subscribe: null,
    unsubscribe: null,
    subscribed: null,
    getVatDeclarations: null,
    companyDeclarations: null,
    getVatDeclaration: null,
    updateVatDeclaration: null,
    createVatDeclaration: null,
    step: null,
    follow: null,
    unfollow: null,
    docs: null,
    docsFromCat: null,
    deleteDoc: null,
    downloadDoc: null,
    updateDoc: null,
    mapping: null,
    countryVatDeclarations: null,
    getOperatorsCountries: null,
    obligation: null,
    obligationCreate: null,
    obligationUpdate: null,
    obligationDelete: null,
    excel: null,
    excelUpdate: null,
    archiveFiles: null,
    deleteFiles: null,
    categories: null,
  },
  errors: {
    subscribe: null,
    unsubscribe: null,
    subscribed: null,
    getVatDeclarations: null,
    companyDeclarations: null,
    getVatDeclaration: null,
    updateVatDeclaration: null,
    createVatDeclaration: null,
    step: null,
    follow: null,
    unfollow: null,
    docs: null,
    docsFromCat: null,
    deleteDoc: null,
    downloadDoc: null,
    updateDoc: null,
    mapping: null,
    countryVatDeclarations: null,
    getOperatorsCountries: null,
    obligation: null,
    obligationCreate: null,
    obligationUpdate: null,
    obligationDelete: null,
    excel: null,
    excelUpdate: null,
    archiveFiles: null,
    deleteFiles: null,
    categories: null,
  },
  loading: {
    subscribe: false,
    unsubscribe: false,
    subscribed: false,
    getVatDeclarations: false,
    companyDeclarations: false,
    getVatDeclaration: false,
    updateVatDeclaration: false,
    createVatDeclaration: false,
    step: false,
    follow: false,
    unfollow: false,
    docs: false,
    docsFromCat: false,
    deleteDoc: false,
    downloadDoc: false,
    updateDoc: false,
    mapping: false,
    countryVatDeclarations: false,
    getOperatorsCountries: false,
    obligation: false,
    obligationCreate: false,
    obligationUpdate: false,
    obligationDelete: false,
    excel: false,
    excelUpdate: false,
    archiveFiles: false,
    deleteFiles: false,
    categories: false,
  },
  subscribe: null,
  unsubscribe: null,
  subscribed: null,
  getVatDeclarations: null,
  companyDeclarations: null,
  getVatDeclaration: null,
  updateVatDeclaration: null,
  createVatDeclaration: null,
  step: null,
  follow: null,
  unfollow: null,
  docs: null,
  docsFromCat: null,
  deleteDoc: null,
  downloadDoc: null,
  mapping: null,
  updateDoc: null,
  countryVatDeclarations: null,
  getOperatorsCountries: null,
  obligation: null,
  obligationCreate: null,
  obligationUpdate: null,
  obligationDelete: null,
  excel: null,
  excelUpdate: null,
  archiveFiles: null,
  deleteFiles: null,
  categories: null,
  error: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

     // delete files
     case actionTypes.GET_CATEGORIES_LOADING:
      return produce(state, (draft) => {
        draft.loading.categories = true;
      });
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.categories = null;
        draft.loading.categories = false;
        draft.success.categories = true;
        draft.categories = action.payload;
      });
    case actionTypes.GET_CATEGORIES_ERROR:
      return produce(state, (draft) => {
        draft.errors.categories = true;
        draft.loading.categories = false;
        draft.success.categories = false;
        !draft.error.includes('categories') &&
          draft.error.push('categories');
      });


     // delete files
     case actionTypes.DELETE_FILES_LOADING:
      return produce(state, (draft) => {
        draft.loading.deleteFiles = true;
      });
    case actionTypes.DELETE_FILES_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteFiles = null;
        draft.loading.deleteFiles = false;
        draft.success.deleteFiles = true;
      });
    case actionTypes.DELETE_FILES_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteFiles = true;
        draft.loading.deleteFiles = false;
        draft.success.deleteFiles = false;
        !draft.error.includes('deleteFiles') &&
          draft.error.push('deleteFiles');
      });

     // session files
     case actionTypes.GET_FULL_LIST_DOCUMENTS:
      return produce(state, (draft) => {
        draft.loading.archiveFiles = true;
      });
    case actionTypes.GET_FULL_LIST_DOCUMENTS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.archiveFiles = null;
        draft.loading.archiveFiles = false;
        draft.success.archiveFiles = true;
        draft.archiveFiles = action.payload;
      });
    case actionTypes.GET_FULL_LIST_DOCUMENTS_ERROR:
      return produce(state, (draft) => {
        draft.errors.archiveFiles = true;
        draft.loading.archiveFiles = false;
        draft.success.archiveFiles = false;
        !draft.error.includes('archiveFiles') &&
          draft.error.push('archiveFiles');
      });

     // Get
     case actionTypes.UPDATE_EXCEL:
      return produce(state, (draft) => {
        draft.loading.excelUpdate = true;
      });
    case actionTypes.UPDATE_EXCEL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.excelUpdate = null;
        draft.loading.excelUpdate = false;
        draft.success.excelUpdate = true;
        draft.excelUpdate = action.payload;
      });
    case actionTypes.UPDATE_EXCEL_ERROR:
      return produce(state, (draft) => {
        draft.errors.excelUpdate = true;
        draft.loading.excelUpdate = false;
        draft.success.excelUpdate = false;
        draft.error.includes('excelUpdate') &&
          draft.error.push('excelUpdate');
      });

     // Get
     case actionTypes.GET_EXCEL:
      return produce(state, (draft) => {
        draft.loading.excel = true;
      });
    case actionTypes.GET_EXCEL_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.excel = null;
        draft.loading.excel = false;
        draft.success.excel = true;
        draft.excel = action.payload;
      });
    case actionTypes.GET_EXCEL_ERROR:
      return produce(state, (draft) => {
        draft.errors.excel = true;
        draft.loading.excel = false;
        draft.success.excel = false;
        draft.error.includes('excel') &&
          draft.error.push('excel');
      });
   
    // Get
    case actionTypes.GET_OBLIGATION:
      return produce(state, (draft) => {
        draft.loading.obligation = true;
      });
    case actionTypes.GET_OBLIGATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.obligation = null;
        draft.loading.obligation = false;
        draft.success.obligation = true;
        draft.obligation = action.payload;
      });
    case actionTypes.GET_OBLIGATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.obligation = true;
        draft.loading.obligation = false;
        draft.success.obligation = false;
        draft.error.includes('obligation') &&
          draft.error.push('obligation');
      });

    // GetNumberReadNotifications
    case actionTypes.OBLIGATION_CREATE:
      return produce(state, (draft) => {
        draft.loading.obligationCreate = true;
      });
    case actionTypes.OBLIGATION_CREATE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.obligationCreate = null;
        draft.loading.obligationCreate = false;
        draft.success.obligationCreate = true;
        draft.obligationCreate = action.payload;
      });
    case actionTypes.OBLIGATION_CREATE_ERROR:
      return produce(state, (draft) => {
        draft.errors.obligationCreate = true;
        draft.loading.obligationCreate = false;
        draft.success.obligationCreate = false;
        draft.error.includes('obligationCreate') &&
          draft.error.push('obligationCreate');
      });

    // PostUpdateOneNotifation
    case actionTypes.OBLIGATION_DELETE:
      return produce(state, (draft) => {
        draft.loading.obligationDelete = true;
      });
    case actionTypes.OBLIGATION_DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.obligationDelete = null;
        draft.loading.obligationDelete = false;
        draft.success.obligationDelete = true;
      });
    case actionTypes.OBLIGATION_DELETE_ERROR:
      return produce(state, (draft) => {
        draft.errors.obligationDelete = true;
        draft.loading.obligationDelete = false;
        draft.success.obligationDelete = false;
      });

      // company with subscriptions
      case actionTypes.GET_ASSIG_COUNTRIES:
        return produce(state, (draft) => {
          draft.loading.getOperatorsCountries = true;
        });
      case actionTypes.GET_ASSIG_COUNTRIES_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.getOperatorsCountries = null;
          draft.loading.getOperatorsCountries = false;
          draft.success.getOperatorsCountries = true;
          draft.getOperatorsCountries = action.payload;
        });
      case actionTypes.GET_ASSIG_COUNTRIES_ERROR:
        return produce(state, (draft) => {
          draft.errors.getOperatorsCountries = true;
          draft.loading.getOperatorsCountries = false;
          draft.success.getOperatorsCountries = false;
          !draft.error.includes('getOperatorsCountries') && draft.error.push('getOperatorsCountries');
        });
  

     // company with subscriptions
     case actionTypes.COUNTRY:
      return produce(state, (draft) => {
        draft.loading.countryVatDeclarations = true;
      });
    case actionTypes.COUNTRY_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.countryVatDeclarations = null;
        draft.loading.countryVatDeclarations = false;
        draft.success.countryVatDeclarations = true;
        draft.countryVatDeclarations = action.payload;
      });
    case actionTypes.COUNTRY_ERROR:
      return produce(state, (draft) => {
        draft.errors.countryVatDeclarations = true;
        draft.loading.countryVatDeclarations = false;
        draft.success.countryVatDeclarations = false;
        !draft.error.includes('countryVatDeclarations') && draft.error.push('countryVatDeclarations');
      });


     // company with subscriptions
     case actionTypes.UPDATE_DOC:
      return produce(state, (draft) => {
        draft.loading.updateDoc = true;
      });
    case actionTypes.UPDATE_DOC_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.updateDoc = null;
        draft.loading.updateDoc = false;
        draft.success.updateDoc = true;
        draft.updateDoc = action.payload;
      });
    case actionTypes.UPDATE_DOC_ERROR:
      return produce(state, (draft) => {
        draft.errors.updateDoc = true;
        draft.loading.updateDoc = false;
        draft.success.updateDoc = false;
        !draft.error.includes('updateDoc') && draft.error.push('updateDoc');
      });

    // company with subscriptions
    case actionTypes.GET_MAPPING:
      return produce(state, (draft) => {
        draft.loading.mapping = true;
      });
    case actionTypes.GET_MAPPING_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.mapping = null;
        draft.loading.mapping = false;
        draft.success.mapping = true;
        draft.mapping = action.payload;
      });
    case actionTypes.GET_MAPPING_ERROR:
      return produce(state, (draft) => {
        draft.errors.mapping = true;
        draft.loading.mapping = false;
        draft.success.mapping = false;
        !draft.error.includes('mapping') && draft.error.push('mapping');
      });

    // company with subscriptions
    case actionTypes.GET_COMPANY_SUBSCRIBED:
      return produce(state, (draft) => {
        draft.loading.subscribed = true;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribed = null;
        draft.loading.subscribed = false;
        draft.success.subscribed = true;
        draft.subscribed = action.payload;
      });
    case actionTypes.GET_COMPANY_SUBSCRIBED_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribed = true;
        draft.loading.subscribed = false;
        draft.success.subscribed = false;
        !draft.error.includes('subscribed') && draft.error.push('subscribed');
      });

     // subscribe
     case actionTypes.UNSUBSCRIBE:
      return produce(state, (draft) => {
        draft.loading.unsubscribe = true;
      });
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = null;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = true;
        draft.unsubscribe = action.payload;
      });
    case actionTypes.UNSUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.unsubscribe = true;
        draft.loading.unsubscribe = false;
        draft.success.unsubscribe = false;
        !draft.error.includes('unsubscribe') && draft.error.push('unsubscribe');
      });

     // subscribe
     case actionTypes.SUBSCRIBE:
      return produce(state, (draft) => {
        draft.loading.subscribe = true;
      });
    case actionTypes.SUBSCRIBE_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.subscribe = null;
        draft.loading.subscribe = false;
        draft.success.subscribe = true;
        draft.subscribe = action.payload;
      });
    case actionTypes.SUBSCRIBE_ERROR:
      return produce(state, (draft) => {
        draft.errors.subscribe = true;
        draft.loading.subscribe = false;
        draft.success.subscribe = false;
        !draft.error.includes('subscribe') && draft.error.push('subscribe');
      });

     // Decla delete docs
     case actionTypes.GET_ALL_COMPANY_VAT_DECLARATIONS:
      return produce(state, (draft) => {
        draft.loading.companyDeclarations = true;
      });
    case actionTypes.GET_ALL_COMPANY_VAT_DECLARATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.companyDeclarations = null;
        draft.loading.companyDeclarations = false;
        draft.success.companyDeclarations = true;
        draft.companyDeclarations = action.payload;
      });
    case actionTypes.GET_ALL_COMPANY_VAT_DECLARATIONS_ERROR:
      return produce(state, (draft) => {
        draft.errors.companyDeclarations = true;
        draft.loading.companyDeclarations = false;
        draft.success.companyDeclarations = false;
        !draft.error.includes('companyDeclarations') && draft.error.push('companyDeclarations');
      });

     // Decla delete docs
     case actionTypes.DOWNLOAD_DOC:
      return produce(state, (draft) => {
        draft.loading.downloadDoc = true;
      });
    case actionTypes.DOWNLOAD_DOC_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.downloadDoc = null;
        draft.loading.downloadDoc = false;
        draft.success.downloadDoc = true;
      });
    case actionTypes.DOWNLOAD_DOC_ERROR:
      return produce(state, (draft) => {
        draft.errors.downloadDoc = true;
        draft.loading.downloadDoc = false;
        draft.success.downloadDoc = false;
      });

     // Decla delete docs
     case actionTypes.DELETE_DOC:
      return produce(state, (draft) => {
        draft.loading.deleteDoc = true;
      });
    case actionTypes.DELETE_DOC_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.deleteDoc = null;
        draft.loading.deleteDoc = false;
        draft.success.deleteDoc = true;
      });
    case actionTypes.DELETE_DOC_ERROR:
      return produce(state, (draft) => {
        draft.errors.deleteDoc = true;
        draft.loading.deleteDoc = false;
        draft.success.deleteDoc = false;
        !draft.error.includes('deleteDoc') && draft.error.push('deleteDoc');
      });

     // Decla delete docs
     case actionTypes.VAT_REPO_GET_DOC_CAT:
      return produce(state, (draft) => {
        draft.loading.docsFromCat = true;
      });
    case actionTypes.VAT_REPO_GET_DOC_CAT_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.docsFromCat = null;
        draft.loading.docsFromCat = false;
        draft.success.docsFromCat = true;
        draft.docsFromCat = action.payload;
      });
    case actionTypes.VAT_REPO_GET_DOC_CAT_ERROR:
      return produce(state, (draft) => {
        draft.errors.docsFromCat = true;
        draft.loading.docsFromCat = false;
        draft.success.docsFromCat = false;
        !draft.error.includes('docsFromCat') && draft.error.push('docsFromCat');
      });


      // Decla add docs
      case actionTypes.VAT_REPO_ADD_DOCUMENTS:
        return produce(state, (draft) => {
          draft.loading.docs = true;
        });
      case actionTypes.VAT_REPO_ADD_DOCUMENTS_SUCCESS:
        return produce(state, (draft) => {
          draft.errors.docs = null;
          draft.loading.docs = false;
          draft.success.docs = true;
        });
      case actionTypes.VAT_REPO_ADD_DOCUMENTS_ERROR:
        return produce(state, (draft) => {
          draft.errors.docs = true;
          draft.loading.docs = false;
          draft.success.docs = false;
          !draft.error.includes('docs') && draft.error.push('docs');
        });
  
  

     // post Decla
     case actionTypes.UNFOLLOW_VAT_DECLARATION:
      return produce(state, (draft) => {
        draft.loading.unfollow = true;
      });
    case actionTypes.UNFOLLOW_VAT_DECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.unfollow = null;
        draft.loading.unfollow = false;
        draft.success.unfollow = true;
      });
    case actionTypes.UNFOLLOW_VAT_DECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.unfollow = true;
        draft.loading.unfollow = false;
        draft.success.unfollow = false;
        !draft.error.includes('unfollow') && draft.error.push('unfollow');
      });


     // post Decla
     case actionTypes.FOLLOW_VAT_DECLARATION:
      return produce(state, (draft) => {
        draft.loading.follow = true;
      });
    case actionTypes.FOLLOW_VAT_DECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.follow = null;
        draft.loading.follow = false;
        draft.success.follow = true;
      });
    case actionTypes.FOLLOW_VAT_DECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.follow = true;
        draft.loading.follow = false;
        draft.success.follow = false;
        !draft.error.includes('follow') && draft.error.push('follow');
      });

     // post Decla
     case actionTypes.UPDATE_STEP:
      return produce(state, (draft) => {
        draft.loading.step = true;
      });
    case actionTypes.UPDATE_STEP_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.step = null;
        draft.loading.step = false;
        draft.success.step = true;
      });
    case actionTypes.UPDATE_STEP_ERROR:
      return produce(state, (draft) => {
        draft.errors.step = true;
        draft.loading.step = false;
        draft.success.step = false;
        !draft.error.includes('step') && draft.error.push('step');
      });

     // post Decla
     case actionTypes.CREATE_VAT_DECLARATION:
      return produce(state, (draft) => {
        draft.loading.createVatDeclaration = true;
      });
    case actionTypes.CREATE_VAT_DECLARATION_SUCCESS:
      return produce(state, (draft) => {
        draft.errors.createVatDeclaration = null;
        draft.loading.createVatDeclaration = false;
        draft.success.createVatDeclaration = true;
      });
    case actionTypes.CREATE_VAT_DECLARATION_ERROR:
      return produce(state, (draft) => {
        draft.errors.createVatDeclaration = true;
        draft.loading.createVatDeclaration = false;
        draft.success.createVatDeclaration = false;
        !draft.error.includes('createVatDeclaration') && draft.error.push('createVatDeclaration');
      });

     // update Declas
     case actionTypes.UPDATE_VAT_DECLARATION:
      return produce(state, draft => {
        draft.loading.updateVatDeclaration = true;
      });

    case actionTypes.UPDATE_VAT_DECLARATION_SUCCESS:
      return produce(state, draft => {
        draft.errors.updateVatDeclaration = null;
        draft.loading.updateVatDeclaration = false;
        draft.success.updateVatDeclaration = true;
      });

    case actionTypes.UPDATE_VAT_DECLARATION_ERROR:
      return produce(state, draft => {
        draft.errors.updateVatDeclaration = true;
        draft.loading.updateVatDeclaration = false;
        draft.success.updateVatDeclaration = false;
        !draft.error.includes("updateVatDeclaration") && draft.error.push("updateVatDeclaration");
      });


    // Get all Declas
    case actionTypes.GET_ALL_VAT_DECLARATIONS:
      return produce(state, draft => {
        draft.loading.getVatDeclarations = true;
      });
    case actionTypes.GET_ALL_VAT_DECLARATIONS_SUCCESS:
      return produce(state, draft => {
        draft.errors.getVatDeclarations = null;
        draft.loading.getVatDeclarations = false;
        draft.success.getVatDeclarations = true;
        draft.getVatDeclarations = action.payload;
      });

    case actionTypes.GET_ALL_VAT_DECLARATIONS_ERROR:
      return produce(state, draft => {
        draft.errors.getVatDeclarations = true;
        draft.loading.getVatDeclarations = false;
        draft.success.getVatDeclarations = false;
        !draft.error.includes("getVatDeclarations") && 
        draft.error.push("getVatDeclarations");
      });

     // Get one Decla
     case actionTypes.GET_ONE_VAT_DECLARATION:
      return produce(state, draft => {
        draft.loading.getVatDeclaration = true;
      });
    case actionTypes.GET_ONE_VAT_DECLARATION_SUCCESS:
      return produce(state, draft => {
        draft.errors.getVatDeclaration = null;
        draft.loading.getVatDeclaration = false;
        draft.success.getVatDeclaration = true;
        draft.getVatDeclaration = action.payload;
      });
    case actionTypes.GET_ONE_VAT_DECLARATION_ERROR:
      return produce(state, draft => {
        draft.errors.getVatDeclaration = true;
        draft.loading.getVatDeclaration = false;
        draft.success.getVatDeclaration = false;
        !draft.error.includes("getVatDeclaration") && draft.error.push("getVatDeclaration");
      });

    // Reset
    case actionTypes.RESET:
      return resetState(state, INITIAL_STATE, action.payload.keys);

    default:
      return state;
  }
};
