import React, { useEffect, useState, useRef} from 'react';
import { useField } from '@formiz/core';

import FileInput from 'components/core/FileInput/FileInput';

const FormizFileInput = (props: FormizFileProps) => {
  const [error, setError] = useState(null);
  const {
    setValue,
    value,
    errorMessage,
    isValid,
    isPristine,
    isSubmitted,
  } = useField(props);
  const { name, label, required, disabled, acceptedFile, mandates, getSuffix, getNewValue } = props;
  const showError = !isValid && (!isPristine || isSubmitted);
  useEffect(() => setError(errorMessage), [errorMessage]);
 
  return (
    <FileInput
      name={name}
      label={label}
      value={value}
      onChange={(e) => {   
        if(acceptedFile.includes(e?.target?.files?.[0]?.type)){     
            setError(null);
            setValue(null);        
              if(mandates === false){
                getSuffix(e.target.files[0])
                getNewValue(e.target.files[0])  
              } 
                setValue(e.target.files[0])                                           
        }else{
          setError('Wrong file type');
          setValue(null);
        }         
      }}
      getClickEvent={() => {}}
      required={required}
      disabled={disabled}
      error={showError && error && error}
    />
  );
};

export interface FormizFileProps {
  name: string;
  value?: string;
  disabled?: true;
  placeholder?: string;
  label?: string;
  required?: any;
  acceptedFile?: any;
  mandates?: boolean;
  getSuffix?: (suffix) => void;
  getNewValue?: (suffix) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default FormizFileInput;
