import React from 'react';
import { Divider, Header, Modal } from 'semantic-ui-react';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Button from 'components/Button';
import { Message, Span } from './styles';


const ModalCloseIntrastat = ({
  open,
  intrastatId,
  isActive,
  declarationType,
  handleCloseIntrastat,
  handleClose,
}: ModalCloseIntrastatProps) => {
  return (
    <Modal open={open} onClose={handleClose} closeIcon size='tiny'>
      <Header icn='cancel' content={`${declarationType.toUpperCase()} ${!isActive ? 'closing' : 'opening'}`} />
     
          <Modal.Content>
        
            <Message>Are you sure you want to {!isActive ? 'close' : 'open'} this declaration ?
              <Span>You will no longer be allowed to make changes</Span>
            </Message>
            <Divider hidden />
            <Wrapper display='flex' justifyContent='center' width='100%'>
              <Button
                type='button'
                color='blue'
                size='small'
                margin='5px'
                content='Yes'
                onClick={() => handleCloseIntrastat(true, intrastatId, !isActive ? 4 : 3)}
              />
              <Button
                type='button'
                color='red'
                size='small'
                margin='5px'
                content='No'
                onClick={() => handleCloseIntrastat(false, intrastatId)}
              />
            </Wrapper>           
        </Modal.Content>     
    </Modal>
  );
};

interface ModalCloseIntrastatProps {
  open: boolean;
  intrastatId: string;
  isActive: boolean;
  declarationType: string;
  handleCloseIntrastat: (response: boolean, intrastatId: string, step?: number) => void;
  handleClose: () => void;
}

export default ModalCloseIntrastat;
