export default {
  colors: {
    mainBackgroundColor: '#f8f8f8',

    //Main button
    mainButton: '#0a326d',
    mainButtonHover: '#0a326de0',
    mainButtonActive: '#16b3e3',
    mainButtonActiveHover: '#16b3e3e0',

    //Action button
    actionButton: '#e0e1e2',
    actionButtonHover: '#cacbcd',
    //Delete button
    deleteButton: '#d11b1b',
    deleteButtonHover: '#d11b1be0',

    //HyperLinks
    hyperlink: '#2c72db',
    hyperlinkHover: '#2c72dbe0',

    //error
    backgroundError: '#fff6f6',
    borderError: '#e0b4b4',
    textError: '#9f3a38',

    //success
    backgroundSuccess: '#fcfff5',
    borderSuccess: '#a3c293',
    textSuccess: '#2c662d',

    //Checkbox
    checkbox: '#12a712',

     //Checkbox
     radio: '#12a712',

    //transparent
    transparent: '#fff0 ',

    background: '#343747',
    grey: '#bababa',
    green: '#50fa7b',
    greenHover: '#44e266',
    blue: '#0085cd',
    red: '#E94949',
    purple: '#D177E6',
    pink: 'hotpink',
    belge: '#C5BA9F',
    white: '#ffffff',

    startStep: '#cd2121',
    inProgressStep: '#dda80b',
    finishedStep: '#21ba45',
    validatedStep: '#00619b',
    cancellededStep: '#d11b1b',

    //Text
    lightBlueText: '#0badd7',

    //BackgroundColor
    transparentLightGreyBg: '#a7a4a421',
    lightGreyBg: '#f9f9f9',
    tableYellow: '#fdc320',
    tableOrange: '#fd6c20',

    //Borders
    segmentBorder: 'rgba(34,36,38,0.15)',
    smallTableBorder: 'rgba(34,36,38,0.15)',
    tableBorder: 'rgba(34,36,38,0.35)',
  },
};
