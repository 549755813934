import React from "react";
import { Table } from "semantic-ui-react";

import Text from "components/Text";

const Index = ({ error }: any) => {
  const line = Object.keys(error);
  const errors = Object.values(error);
  return (
    <Table striped celled columns={2}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Text>Line</Text>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <Text>Errors</Text>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!errors && (
       
            <Table.Row>
              <Table.Cell>Internal Server Error</Table.Cell>
              <Table.Cell>500</Table.Cell>
            </Table.Row>
    
        )}
      {errors.length>10000 && typeof errors === "object" && (
          <Table.Row>
          <Table.Cell>Lambda</Table.Cell>
          <Table.Cell>[Lambda/formater] - validation error detected in excel document</Table.Cell>
          </Table.Row>
        ) || errors.map((value: any, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>{+line[index] + 1}</Table.Cell>
              <Table.Cell>
                <ul>
                  {value.map((e, index) => {
                    return <li key={index}>{e}</li>;
                  })}
                </ul>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

export default Index;
