import React from "react";

import { FormGroup } from "./styles";

const Index = ({ children, inline }: any) => {
  return (
    <FormGroup widths="equal" inline={inline}>
      {children}
    </FormGroup>
  );
};

export default Index;
