import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Divider, Icon, Table, Header } from 'semantic-ui-react';
import { actions, selectors } from 'store';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import Button from 'components/Button';
import ButtonBack from 'components/ButtonBack/ButtonBack';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { paths } from 'routes/fiscal/repository';
import Pagination from 'components/Pagination';
import { Spacer } from 'components/core';
import SearchFilters from './Components/SearchFilters/searchFilters';
import { FList, Followers, Period, State } from '../CompanyDashboard/styles';
import { getMonth, getYear, shortDate } from 'services/helper/dateFormater';
import { Company } from './monitoringStyles';


const Index = ({
  dispatch,
  history,
  subscribed,
  vatDeclarations,
  vatDeclarationsLoading
}) => {
  const LIMIT = 8;
  const PAGE = 1

  const [filter, setFilter] = useState('all');
  const [column, setcolumn] = useState(null);
  const [direction, setdirection] = useState(null);
  const [data, setdata] = useState(null);
  const [count, setCount] = useState(0);
  const [filterBar, setfilterBar] = useState(false);
  const [resetPagination, setresetPagination] = useState(false);
  const [page, setPage] = useState(null)
  const [resetP, setResetP] = useState(null)

  let storedPage = useMemo(() => sessionStorage.getItem('page') ? Number(sessionStorage.getItem('page')) : 1, [])
  let storedSearch = useMemo(() => JSON.parse(sessionStorage.getItem('search')), [])

  const initSession = (storedPage, storedSearch) => {
    let storage = Number(sessionStorage.getItem('page')) || false
    if(storage){
      setPage(storage)
    }else{
      if(storedSearch){
        sessionStorage.setItem('page', storedSearch.page.toString())
        setPage(storedSearch.page)
      }else if(storedPage){
        setPage(storedPage)
      }
   
    }
  }

  useEffect(() => {
   
      initSession(storedPage, storedSearch)
      //setfilterBar(true)
  
      let initQueryTab = {}
      Object.assign(initQueryTab, {
        limit: LIMIT,
        page: (storedSearch && storedSearch.page) ? storedSearch.page : storedPage,
        ...storedSearch && {
          ...storedSearch.showAll && {showAll: storedSearch.showAll},
          ...storedSearch.id && {id: storedSearch.id},
          ...storedSearch.taxPeriod && {taxPeriod: storedSearch.taxPeriod},
          ...storedSearch.companyId && {companyId: Number(storedSearch.companyId)},
          ...storedSearch.from && {from: storedSearch.from},
          ...storedSearch.to && {to: storedSearch.to},
          ...storedSearch.clientReference && {clientReference: storedSearch.clientReference},
          ...storedSearch.intranetReference && {intranetReference: storedSearch.intranetReference},
          ...storedSearch.purchaseNumber && {purchaseNumber: storedSearch.purchaseNumber}
        }
      })

      dispatch(
        actions.fiscalVat.getAllVatDeclarations({
          query: initQueryTab,
        })
      );
    
    
    dispatch(actions.fiscalVat.getCompanySubscribed());
    dispatch(actions.socket.subscribeVatDeclarationsProcessing());

    return () => {
      dispatch(
        actions.fiscalVat.reset([
          'error',
          'subscribed',
          'getVatDeclarations',
        ])
      );
      dispatch(actions.socket.unsubscribeVatDeclarationsProcessing());
    }
  }, [dispatch, storedPage, storedSearch])


  useEffect(() => {
   if(vatDeclarations){
    setdata(vatDeclarations.rows);
    setCount(vatDeclarations.count)
   }
  }, [vatDeclarations]);

 
  useEffect(() => {
    if(data){
      setresetPagination(false);
    }
  }, [data]);


  const getDataBySearchFilter = useCallback((search, dataPage: number) => {

      let queryTab = {}
      // dataPage: pagination page number
      let newPage = 
      typeof dataPage === 'number' 
      ? dataPage 
      : 1

     Object.assign(queryTab, {
          limit: LIMIT,
          page: newPage,
          showAll: search.showAll,
          ...search.id && {id: search.id},
          ...search.taxPeriod && {taxPeriod: search.taxPeriod},
          ...search.companyId && {companyId: Number(search.companyId)},
          ...search.from && {from: search.from},
          ...search.to && {to: search.to},
          ...search.clientReference && {clientReference: search.clientReference},
          ...search.intranetReference && {intranetReference: search.intranetReference},
          ...search.purchaseNumber && {purchaseNumber: search.purchaseNumber}
        })
          
      dispatch(
        actions.fiscalVat.getAllVatDeclarations({
          query: queryTab,
        })
      );

      sessionStorage.setItem('search', JSON.stringify(queryTab));
      sessionStorage.setItem('page', newPage.toString())

      typeof dataPage === 'number' 
      ? setPage(newPage) 
      : setResetP(true)
  
      return () => {
        dispatch(actions.fiscalVat.reset(['getVatDeclarations', 'error']));
      }
  }, [dispatch]);

  const resetSearchFilters = () => {
      sessionStorage.removeItem('search');
      sessionStorage.setItem('page', PAGE.toString());
      setPage(null)
      setResetP(true)

      dispatch(
        actions.fiscalVat.getAllVatDeclarations({
          query: {
            limit: LIMIT,
            page: PAGE,
          },
        })
      );
      setfilterBar(!filterBar)
      return () => {
        dispatch(
          actions.fiscalVat.reset(['getVatDeclarations', 'error'])
        );
      }
  };

  const handleSort = (name) => {
    if(column !== name){
      setcolumn(name);
      setdirection('descending');
      setdata(_.sortBy(data, [name]));
      return;
    }
    setdata(data.reverse());
    setdirection(direction === 'descending' ? 'ascending' : 'descending');
  };

  const handleFilter = (name) => {
    switch(name) {
      case 'processing':
        const result = vatDeclarations.rows.filter((e) => e.state === 'processing')
        setdata(result);
        setFilter(name)
        break;

      default:
        setFilter(name)
        setdata(vatDeclarations.rows) 
    }
  };


  const handlePageChange = useCallback((event, { activePage }) => {
    if(event){
      setResetP(null)
      if(storedSearch){
           getDataBySearchFilter(storedSearch, activePage)
      }else{
        dispatch(
          actions.fiscalVat.getAllVatDeclarations({
            query: {
              limit: LIMIT,
              page: activePage,
            },
          })
        );
        setPage(activePage.toString())
        setResetP(null)
        sessionStorage.setItem('page', activePage.toString());
    }
  }
  }, [dispatch, storedSearch, getDataBySearchFilter]);




  return (
    <Wrapper overflow='auto' width='90%' margin='auto'>
      <Divider hidden />
      <Wrapper display='flex'>
        <ButtonBack history={history} />
      </Wrapper>
      <Divider hidden />
      {(
        vatDeclarations === null || 
        vatDeclarationsLoading || 
        data === null
        ) && <Loader content='Loading' />}

        <Wrapper>
          <Wrapper display='flex' justifyContent='center'>
            <Header size='huge'>Vat Declarations Monitoring</Header>
          </Wrapper>
          <Spacer height='30px' />
          <Wrapper display='flex' justifyContent='space-between'>
            <p>Number of vatDeclarations displayed with filters: {(vatDeclarations && vatDeclarations.count) ? vatDeclarations.count : 0}</p>
            <p>Total number of reports in progress:{' '}{(vatDeclarations && vatDeclarations.count) ? vatDeclarations.count : 0}</p>
          </Wrapper>
          <Wrapper display='flex' justifyContent='space-between'>

            <Wrapper display='flex' justifyContent='space-between'>
              <Button
                icon='filter'
                type='button'
                content='Search Filters'
                color='blue'
                onClick={() => setfilterBar(!filterBar)}
              />
            </Wrapper>
           
            <Wrapper display='flex' justifyContent='center'>
              <Button
                type='button'
                content='All'
                color={filter === 'all' ? 'activeBlue' : 'blue'}
                onClick={() => handleFilter('all')}
              />
              <Button
                type='button'
                content='Processing'
                color={filter === 'processing' ? 'activeBlue' : 'blue'}
                onClick={() => handleFilter('processing')}
              />
            </Wrapper>

          </Wrapper>

          <Wrapper display='flex' justifyContent='flex-end'>
            <SearchFilters
              isVisible={filterBar}
              companies={subscribed}
              applyFilters={getDataBySearchFilter}
              resetFilters={resetSearchFilters}
            />
          </Wrapper>
          <Table columns={8} stackable sortable selectable striped>
            <Table.Header>
              <Table.Row>

                <Table.HeaderCell
                  sorted={column === 'id' ? direction : null}
                  onClick={() => handleSort('id')}
                >
                  Declaration
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === 'company' ? direction : null}
                  onClick={() => handleSort('company')}
                >
                  Company
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === 'taxPeriod' ? direction : 'descending'}
                  onClick={() => handleSort('taxPeriod')}
                >
                  Tax Period
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={column === 'createdAt' ? direction : 'descending'}
                  onClick={() => handleSort('createdAt')}
                >
                  Created On
                </Table.HeaderCell>

                <Table.HeaderCell>
                  Country
                </Table.HeaderCell>

                <Table.HeaderCell>
                  Emitter
                </Table.HeaderCell>

                <Table.HeaderCell>
                  Followers
                </Table.HeaderCell>

                <Table.HeaderCell>
                  Status
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {((!data || data.length === 0) && <EmptyTable colNumber={8} />) ||
                data.map((e, i) => {
                  return (
                    <Table.Row key={i} warning={e.operatorNewMessage} style={{'min-height': '50px'}}>
                      <Link
                        to={paths.repositoryDetails
                          .replace(':companyId', e.Company_Id)
                          .replace(':declarationId', e.id)}
                        className='table-link'
                      >                    
                        <Table.Cell>
                          <Wrapper
                            display='flex'
                            justifyContent='space-between'
                          >
                            {e.id}
                            {e.operatorNewMessage && (
                              <Icon name='envelope' color='red' size='large' />
                            )}
                          </Wrapper>
                        </Table.Cell>
                        <Table.Cell><Company>{e.Company.name}</Company></Table.Cell>
                        <Table.Cell>
                        <Period>
                          {getMonth(new Date(e.taxPeriod))} - {getYear(new Date(e.taxPeriod))}                   
                        </Period>                      
                        </Table.Cell>
                        <Table.Cell>
                          {shortDate(new Date(e.createdAt))}             
                        </Table.Cell>
                        <Table.Cell>{e.country}</Table.Cell>                    
                        <Table.Cell>{e.emitter.fullname}</Table.Cell>
                        <Table.Cell>
                          <Followers>
                          {
                            e.follower && 
                            e.follower.length &&  
                            e.follower.map((user, i) => (
                                  <FList key={i}>{`${user.operator}`}</FList>
                                ))
                          }
                          </Followers>                                          
                        </Table.Cell>
                        <Table.Cell>
                          <State defaultValue={e.state === 'cancelled' ? 'red' : e.state === 'processing' ? '#dda80b' : '#00619b'}>{e.state}</State>
                        </Table.Cell>
                      </Link>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>    
      <Divider hidden />
      {!resetPagination && count > 0 && (
        <Wrapper display='flex' justifyContent='center'>
          <Pagination
            totalPages={count / LIMIT}
            defaultActivePage={PAGE}
            storedPage={page}
            resetPagination={resetP}
            onChange={handlePageChange}
          />
        </Wrapper>
      )}
    </Wrapper>
  );
      
};

const mapStateToProps = (state) => {
  return {
    subscribed: selectors.fiscalVat.subscribedSelector(state),
    vatDeclarations: selectors.fiscalVat.getfiscalVatDeclarationsSelector(state),
    vatDeclarationsLoading: selectors.fiscalVat.getfiscalVatDeclarationsLoadingSelector(state)
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
