import React from "react";
import { getIn } from "formik";
import { Form, Popup, Icon } from "semantic-ui-react";

import { Wrapper } from "./styles";

const Index = ({
  label,
  field,
  meta,
  value,
  check,
  onChange,
  description
}: any) => {
  return (
    <Wrapper>
      <Form.Checkbox
        value={value}
        name={field.name}
        label={label}
        defaultChecked={check}
        error={
          meta.touched[field.name.split("[")[0]] &&
          getIn(meta.errors, field.name)
            ? true
            : false
        }
        onChange={(e, { name, value }) => {
          if (onChange) onChange(value);
          else meta.setFieldValue(name, value);
        }}
      />
      <Popup trigger={<Icon name="info circle" />} content={description} />
    </Wrapper>
  );
};

export default Index;
