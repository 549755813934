import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Operator from 'views/Fiscal/Vat/Operator';
import Service from 'views/Fiscal/Vat/Service';
import ServicePaused from 'components/ServicePaused';
import { selectors, actions } from 'store';
import Loader from 'components/Loader';
import { Container } from 'semantic-ui-react';

const Index = ({
  user,
  dispatch,
  history,
  companySelected,
  hasSubscribedTo,
}: any) => {

  const userAdminOrOp = user.admin || user.operator
  const [uniqueId, setUniqueId] = useState(null)

  useEffect(() => {
    if (companySelected) {
      setUniqueId(Date.now())
      dispatch(
        actions.company.hasSubscribedTo({
          params: {
            companyId: companySelected,
            serviceName: 'TaxFlow',
          },
        })
      )
    } else {
      dispatch(actions.company.reset(['hasSubscribedTo', 'error']));
    }

    return () => {
      dispatch(actions.company.reset(['hasSubscribedTo', 'error']));
    };
  }, [dispatch, companySelected]);


  return (
    (companySelected && hasSubscribedTo && !hasSubscribedTo.pause && uniqueId !== null && (
      <Service history={history} dispatch={dispatch} companySelected={companySelected} />
    )) || 
    ((companySelected === null || hasSubscribedTo === false) && !userAdminOrOp && (
      <Container textAlign='center'>
        <h1>You have not subscribed to this service</h1>
      </Container>
    )) || 
    (hasSubscribedTo && hasSubscribedTo.pause && (
      <ServicePaused history={history} companySelected={companySelected} />
    )) || 
    (userAdminOrOp && (
      <Operator dispatch={dispatch} history={history} user={user} companySelected={companySelected}/>
    )) || 
    <Loader />
  );
};

const mapStateToProps = (state) => {
  return {
    user: selectors.auth.userSelector(state),
    companySelected: selectors.auth.companySelected(state),
    hasSubscribedTo: selectors.company.hasSubscribedToSelector(state),

  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
