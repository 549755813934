const successSelector = (state) => state.message.success;
const errorSelector = (state) => state.message.error;
const getErrorSelector = (state) => state.message.getError;
const messageSelector = (state) => state.message.message;
const httpCodeSelector = (state) => state.message.httpCode;
export default {
  successSelector,
  errorSelector,
  getErrorSelector,
  messageSelector,
  httpCodeSelector,
};
