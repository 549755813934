import React from "react";
import ClickableText from "components/ClickableText";
import TextColored from "components/QuickFixes/DeliveryForm/TextColored";
import { Label, Table } from "semantic-ui-react";
import styled from "@emotion/styled";

const Index = ({ data, path, action, history, proofsNbr }: any) => {



  const ProofNumber = styled.div(() => ({
      position: 'absolute',
      top: '-12px',
      right: '-12px',
      fontSize: '10px',
      width: '18px',
      padding: '0',
      textAlign: 'center',
      lineHeight: '15px',
      height: '15px',
      borderRadius: '0rem 0rem 0.3rem 0.3rem',
      color: '#000',
      background: '#e4e3e3',
  }));

  return (
    <>
      {(data === "awaiting for proof" && (
        <Table.Cell>
          <ClickableText color="red" display='block'  onClick={action}>
            Awaiting for proof<ProofNumber>{proofsNbr && proofsNbr.length}</ProofNumber>
          </ClickableText>
        </Table.Cell>
      )) ||
        (data === "awaiting for validation" && (
          <Table.Cell>
            <ClickableText
              display='block'  
              onClick={() => history.push(path)}
              color="darkorange"
            >
              Awaiting for validation<ProofNumber>{proofsNbr && proofsNbr.length}</ProofNumber>
            </ClickableText>
          </Table.Cell>
        )) ||
        (data === "current file are validated" && (
          <Table.Cell>
            <TextColored color="limegreen">
              Current files are validated
            </TextColored>
          </Table.Cell>
        ))}
    </>
  );
};

export default Index;
