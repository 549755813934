import { orderBy } from 'lodash';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Divider,
  Form,
  Grid,
  Header,
  Modal,
} from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions, selectors } from 'store';
import Button from 'components/Button';
import { updateIntrastat } from 'interfaces/formSchema/customsValidators';
import Dropdown from 'components/Dropdown';
import Input from 'components/Form/Input';

const ModalEditIntrastat = ({
  open,
  actualData,
  handleEditIntrastat,
  handleClose,
  intl,
  dispatch,
  euCountries,
  euCountriesLoading,
  companyId,
}: ModalEditIntrastatProps) => {

  const [allCountriesList, setAllCountriesList]: any = useState([]);

  useEffect(() => {
    dispatch(actions.data.getEuCountries());

    return () => {
      dispatch(actions.data.reset(['euCountries', 'error']));
    };
  }, [dispatch, companyId]);

  useEffect(() => {
    if(euCountries){
      setAllCountriesList(
        orderBy(
          euCountries.map((country, index) => {
            return {
              key: index,
              value: country.country_code,
              text: intl.formatMessage({
                id: `countries.name.${country.country_code}`,
              }),
              flag: country.country_code.toLowerCase(),
            };
          }),
          ['text']
        )
      );
    }
  }, [euCountries, intl]);


  return (
    <Modal open={open} onClose={handleClose} closeIcon>
      <Header icon='edit' content='Edit DEB' />
      <Formik
        initialValues={{
          period: actualData.period.split('T')[0] || '',
          filingCountry: actualData.filingCountry || '',
          declarationType: actualData.declarationType || '', 
          flow: actualData.flow || '',
          clientReference: actualData.clientReference || '',
        }}
        enableReinitialize={true}
        onSubmit={handleEditIntrastat}
        validationSchema={updateIntrastat}
      >
        {({
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          values,
          isSubmitting,
        }) => (
        <Modal.Content>
        <Form onSubmit={handleSubmit}>
        <Grid>
        <Grid.Row columns='2'>
        <Grid.Column> 
          <Field
            mandatory
            label='Declaration type'
            name='declarationType'
            placeholder='Choose declaration type...'
            component={Dropdown}
            options={[
              {
                key: 0,
                value: 'DEB',
                text: 'DEB'
              },
              {
                key: 1,
                value: 'DES',
                text: 'DES'
              },
              {
                key: 2,
                value: 'AVOIR',
                text: 'Avoir'
              },
              {
                key: 3,
                value: 'NON_CONFORME',
                text: 'Non conforme'
              },
              {
                key: 4,
                value: 'none',
                text: 'NONE'
              },
            ]}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            mandatory
            label='Flow type'
            placeholder='Choose flow'
            name='flow'
            component={Dropdown}
            options={[
              {
                  key: 0,
                  value: 'expedition',
                  text: 'Expedition'
              },
              {
                  key: 1,
                  value: 'introduction',
                  text: 'Introduction'
              },
            ]}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
        <Grid.Column>
          <Field
            mandatory
            label='Reporting period'
            name='period'
            component={Input}
            type='date'
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>     
        <Grid.Column>
          <Field
            mandatory
            label='Filing country'
            name='filingCountry'
            placeholder='choose...'
            component={Dropdown}
            options={allCountriesList}
            loading={euCountriesLoading}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
        </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
          <Field
            label='Client reference'
            name='clientReference'
            placeholder='REF5765...'
            component={Input}
            meta={{
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
            }}
          />
          </Grid.Column>
        </Grid.Row>
        </Grid>
        <Divider hidden />
        <Divider hidden />
        <Button
          disabled={isSubmitting}
          type='submit'
          content='Submit'
          color='green'
        />
        </Form>
      </Modal.Content>
      )}
    </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    euCountries: selectors.data.euCountriesSelector(state),
    euCountriesLoading: selectors.data.euCountriesLoadingSelector(state),
    errorHandlerData: selectors.data.errorSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

type declarationData = {
  period: string;
  filingCountry: string;
  declarationType: string;
  flow: string;
  clientReference: string;
};

interface ModalEditIntrastatProps {
  open: boolean;
  actualData: any;
  handleEditIntrastat: (values: declarationData) => void;
  handleClose: () => void;
  dispatch: any;
  intl;
  euCountries;
  euCountriesLoading;
  companyId;
}

export default enhance(ModalEditIntrastat);
