import React, { FunctionComponent, useEffect, useRef } from 'react';
import { actions, selectors } from 'store';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { MessageWrapper, CloseButton } from './floatingMessageStyles';
import { Divider } from 'semantic-ui-react';
import { TextCustom } from 'components/core';

const FloatingMessage: FunctionComponent<FloatingMessageI> = ({
  error,
  dispatch,
  message,
  httpCode,
}) => {
  const floatingMessageRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        floatingMessageRef.current &&
        !floatingMessageRef.current.contains(event.target)
      )
        handleClose();
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [floatingMessageRef]);

  const handleClose = () =>
    dispatch(actions.message.reset(['httpCode', 'message', 'error']));

  return (
    <MessageWrapper ref={floatingMessageRef} error={error}>
      <CloseButton error={error} onClick={handleClose} />
      <TextCustom fontWeight='bold'>
        {(!error && 'Success') || 'Error'}
      </TextCustom>

      {message && message.length > 1 && (
        <>
          <Divider />
          <p>{message}</p>
          {httpCode && !message.match(/already/g) && httpCode.toString()[0] !== '4' && (
            <>
              <Divider />
              <p>
                Try to reload the page. If the error persists, please contact
                us.
              </p>
            </>
          )}
        </>
      )}
    </MessageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    message: selectors.message.messageSelector(state),
    error: selectors.message.errorSelector(state),
    httpCode: selectors.message.httpCodeSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

interface FloatingMessageI {
  dispatch: any;
  error: boolean;
  message: string;
  httpCode: number;
}

export default enhance(FloatingMessage);
