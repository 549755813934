import { addLocaleData } from 'react-intl';
import fr from 'react-intl/locale-data/fr';
import lang from './lang';

addLocaleData([...fr]);

export default {
  getMessages(region) {
    return lang[region] || lang.fr;
  },
};
