import React, { useEffect } from 'react';

import { PaginationProps } from './type';
import { CustomPagination } from './styles';

const Pagination = ({
  totalPages,
  onChange,
  defaultActivePage = 1,
  resetPagination = false,
  storedPage,
}: PaginationProps) => {
 
  return (
    <CustomPagination
      hidden={totalPages}
      totalPages={Math.ceil(totalPages)}
      defaultActivePage={storedPage ? storedPage : defaultActivePage}
      onPageChange={onChange}
      {...(resetPagination && { activePage: 1 })}
    />
  );
};

export default Pagination;
