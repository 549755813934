import React, { useRef } from 'react';
import { Field, Formik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Icon } from 'semantic-ui-react';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Wrapper from 'components/core/Wrapper/Wrapper';
import Segment from 'components/core/Segment/Segment';
import { FiscalPeriod } from 'components/Form';
import { monitoringFiltersSchema } from 'interfaces/formSchema/taxflowValidators';
import { Input } from 'components/Form';
import Dropdown from 'components/Dropdown';
import { VertSep } from './styles';


const Index = ({
  dispatch,
  applyFilters,
  searchLoading,
  resetFilters,
  isoList,
  isVisible,
}: any) => {
  const searchRef = useRef()

  type session = {
    taxflowId: number,
    taxPeriod: string,
    intranetReference: string,
    countryIso: string,
    showAll: boolean,
  }

  const data: session = JSON.parse(sessionStorage.getItem('taxflow_search'))

  return (
    <Segment
      {...(!isVisible && { display: 'none' })}
      margin='1rem 0 0 0'
      width='100%'
    >
      <Formik
        ref={searchRef}
        enableReinitialize={true} 
        validateOnChange={true}
        initialValues={data ? {
          taxflowId: data.taxflowId || '',
          taxPeriod: data.taxPeriod || '',
          intranetReference: data.intranetReference || '',
          countryIso: data.countryIso || '',
          showAll: data.showAll ? data.showAll : false,
        } : {
          taxflowId: '',
          taxPeriod: '',
          intranetReference: '',
          countryIso: '',
          showAll: false,
        }}
        onSubmit={applyFilters}
        validationSchema={monitoringFiltersSchema}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper
              display='flex'
              justifyContent='center'
              flexDirection='column'
            >
             
              <Wrapper 
                display='flex' 
                margin='0.5rem' 
                justifyContent='center'
              >
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Taxflow reference...'
                    name='taxflowId'
                    id='taxflowId'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>                                             
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Intranet reference'
                    name='intranetReference'
                    id='intranetReference'
                    type='text'
                    component={Input}
                    meta={{ 
                      errors, 
                      touched 
                    }}
                    margin='20px'
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='taxPeriod'
                    name='taxPeriod'
                    id='taxPeriod'
                    component={FiscalPeriod}
                    meta={{ 
                      errors, 
                      touched, 
                      setFieldValue, 
                      setFieldTouched 
                    }}
                  />
                </Wrapper>
                <Wrapper margin='0.5rem' width='180px'>
                  <Field
                    placeholder='Country...'
                    name='countryId'
                    id='countryId'
                    options={isoList}
                    component={Dropdown}
                    meta={{
                      errors,
                      touched,
                      setFieldValue,
                      setFieldTouched,
                    }}
                  />
                </Wrapper>                                                            
              </Wrapper>
            </Wrapper>
            <Wrapper display='flex' margin='1rem' justifyContent='center' alignItems='center'>
                <Field              
                  name='showAll'
                  id='showAll'
                  label='Archives'
                  margin='20px'
                  checked={values.showAll}
                  component={Checkbox}
                  meta={{ 
                    errors, 
                    touched,
                    setFieldValue, 
                    setFieldTouched 
                  }}
                  onChange={() => {
                    setFieldValue('showAll', !values.showAll)
                  }}
                />
              <VertSep />
              <Button
                disabled={searchLoading}
                {...(searchLoading && {
                  icon: <Icon loading name='spinner' />,
                })}
                type='submit'
                content='Apply'
                color='green'
              />
              <Button
                type='button'
                content='Reset'
                color='red'
                onClick={() => {
                  resetFilters();
                  resetForm();
                }}
              />
            </Wrapper>
          </Form>
        )}
      </Formik>
    </Segment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);

