import React from "react";
import { ErrorMessage } from "formik";

import { Error } from "components/Form";
import { Wrapper } from "./styles";

const Index = ({ label, field, meta }: any) => {
  return (
    <Wrapper>
      <label>{label}</label>
      <input
        name={field.name}
        type="date"
        onBlur={field.onBlur}
        onChange={field.onChange}
      />

      <ErrorMessage name={field.name} render={msg => <Error msg={msg} />} />
    </Wrapper>
  );
};

export default Index;
