import React from 'react';
import TextCustom from 'components/core/TextCustom/TextCustom';
import styled from '@emotion/styled';
import { Icon, Popup } from 'semantic-ui-react';

const Error = styled.div(() => ({
    color: '#db2828',
    padding: '0.3rem',
    fontSize: '12px',
    display: 'inline-block',
    lineHeight: '12px'
}));

const Space = styled.div(() => ({
  display: 'inline-block',
  width: '0',
  margin: '0 5px',
}));

const InfosTooltip = (props) => {
  return (
    <TextCustom>
      {props.content}
      <Space />
      <Popup
        trigger={<Icon name='info circle' />}
        content={`${props.description}`}
        inverted
      />
    </TextCustom>
  );
};

export default InfosTooltip;
