import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Divider, Header, Step, Table } from 'semantic-ui-react';
import { actions, selectors } from 'store';

import Button from 'components/Button';
import NewOssImport from 'views/Fiscal/Oss/New/index';
import EmptyTable from 'components/EmptyTable';
import Wrapper from 'components/core/Wrapper/Wrapper';
import { reallyShortDate } from 'services/helper/dateFormater';

const Index = ({ dispatch, integrations, history }) => {
  const [modalStatus, setmodalStatus] = useState(false);
  const [data, setdata] = useState(null);

  useEffect(() => {
    dispatch(actions.fiscalOss.reset(['error']));
    dispatch(actions.fiscalOss.getIntegrations());
    dispatch(actions.socket.subscribeIntegrations());

    return () => {
      dispatch(actions.fiscalOss.reset(['integrations', 'error']));
      dispatch(actions.socket.unsubscribeIntegrations());
    };
  }, []);

  useEffect(() => {
    if (integrations !== null) {
      setdata(_.orderBy(integrations, ['createdAt'], ['desc']));
    }
  }, [integrations]);

  return (
    <Wrapper overflow='auto' width='90%' margin='auto'>
      <Divider hidden />
      <Header textAlign='center' size='huge'>
        Add OSS
      </Header>
      <Container>
        <Wrapper display='flex' justifyContent='space-between'>
          <Button
            labelPosition='right'
            position='left'
            icon='plus circle'
            type='button'
            content='Create new OSS import'
            onClick={() => setmodalStatus(true)}
          />
        </Wrapper>
      </Container>
      <Divider hidden />
      {modalStatus && (
        <NewOssImport
          open={modalStatus}
          handleClose={() => setmodalStatus(false)}
        />
      )}
      <Container>
        <Table columns='4' selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Company Name</Table.HeaderCell>
              <Table.HeaderCell>CF ID</Table.HeaderCell>
              <Table.HeaderCell>Created on</Table.HeaderCell>
              <Table.HeaderCell>Current state</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {((!data || data.length === 0) && <EmptyTable colNumber={4} />) ||
              data.map((e, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell>{e.Company.name}</Table.Cell>
                    <Table.Cell>{e.cfId}</Table.Cell>
                    <Table.Cell>
                      {reallyShortDate(new Date(e.createdAt))}
                    </Table.Cell>
                    <Table.Cell>
                      <Step.Group size='small'>
                        <Step>{e.state}</Step>
                      </Step.Group>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    integrations: selectors.fiscalOss.integrationsSelector(state),
  };
};

const enhance: any = compose(injectIntl, connect(mapStateToProps, null));

export default enhance(Index);
