import React, { useEffect } from 'react';
import KRGlue from '@lyracom/embedded-form-glue';

import payzenConfig from 'config/payzen.json';
import { Wrapper } from './styles';

const Index = ({ formToken, onSubmit }) => {
  useEffect(() => {
    const payzenKey = 
                  process.env.REACT_APP_STAGE === "production" ? 
                  payzenConfig.publicKeyProd : 
                  payzenConfig.publicKeyTest;

    const createForm = async () => {
      KRGlue.loadLibrary(payzenConfig.endpoint, payzenKey).then(
        async ({ KR }) => {
          KR.removeForms();
          await KR.setFormConfig({
            formToken,
            'kr-language': 'en-US',
          });
          const { result } = await KR.addForm('#paymentForm');
          await KR.showForm(result.formId);
          KR.onSubmit((e) => {
            onSubmit();
            KR.removeForms();
          });
        }
      );
    };

    formToken && createForm();

    return () => {
      KRGlue.loadLibrary(payzenConfig.endpoint, payzenKey).then(
        async ({ KR }) => {
          KR.removeForms();
        }
      );
    };
  }, [formToken]);

  return (
    <div className='form'>
      <Wrapper className='container'>
        <div id='paymentForm'></div>
      </Wrapper>
    </div>
  );
};

export default Index;
