import button from "./button";
import input from "./input";
import services from "./services";

export default {
  ...input,
  ...button,
  ...services,
  "app.title": "My ASD"
};
