import React from 'react';
import BodyStyle from './styles';
import { Global, css } from '@emotion/core';
import bg from 'assets/images/bg.png';
import bgBottom from 'assets/images/bg-bottom.png';

const Index = (props: any) => {
  return (
    <>
      <Global
        styles={(props) =>
          css`
            body {
              background-color: ${props.colors.mainBackgroundColor};
              background-image: url(${bg});
              background-position: top;
              background-repeat: repeat-y;
              background-size: cover;
            }
          `
        }
      />
      <BodyStyle bgBottom={bgBottom}>{props.children}</BodyStyle>
    </>
  );
};

export default Index;
