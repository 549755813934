import React from 'react';
import { Table, Button as Btn, Icon } from 'semantic-ui-react';
import Button from 'components/Button';
import ButtonValidationModal from 'components/ButtonValidationModal';
import { Bullet, Open, OpenTag } from './DocumentsStyles';

const Documents = ({
  userAdminOrOp,
  isOwner,
  user,
  docList,
  document,
  declarationState,
  index,
  handleShowDocument,
  handleDeleteDocument,
  openTagManager,
}: DocumentsProps) => {

    let icon
    switch(document.status){
      case 'archive':
        icon = 'folder open outline';
        break;
  
    default:
        icon = 'file pdf';
    }

    const bullet = () => {
      return document.validation === null ? 
      'transparent' : ((document.IntrastatDocumentCategory_Id === 11 || document.IntrastatDocumentCategory_Id === 18) && document.validation === 0) ? 
      'orange' : ((document.IntrastatDocumentCategory_Id === 11 || document.IntrastatDocumentCategory_Id === 18) && document.validation === 1) ? 'green' : 'red'
    }

    // if document type is not (VATListing - 10) anyone can open tagManager
    // if document type is (VATListing - 10) and user is Operator, document can be downloaded
    // if documentg type is (State - 11) you cannot delete it

    return (  
        <>
        <Table.Row key={index}>
            <Table.Cell>{
            (userAdminOrOp && document.IntrastatDocumentCategory_Id !== 10 && 
            <OpenTag onClick={()=> openTagManager()}>
              {document.status && (
                <Icon
                  name={document.split ? 'folder open' : icon}
                  size='small'
                  color='black'                    
                />
              )}{document.name}</OpenTag>) || (<Open><Icon
                name={document.split ? 'folder open' : icon}
                size='small'
                color='black'                    
              />{document.name}</Open>)}</Table.Cell>
            <Table.Cell>
              {document.IntrastatDocumentCategory.name}
              <Bullet color={bullet()}/>
            </Table.Cell>
            <Table.Cell>
              {new Date(
                document.createdAt
              ).toLocaleDateString('en', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Table.Cell>
            <Table.Cell>            
              <Btn.Group vertical widths='two'>
                {!userAdminOrOp && (
                  <Button
                    type='submit'
                    margin='5px'
                    content='Show'
                    color='blue'
                    onClick={() => openTagManager()}
                  />              
                )}
                <ButtonValidationModal
                  noSubmit
                  trgrContent='Delete'
                  headerContent='Delete'
                  modalContent='Do you confirm the deletion of this document?'
                  trgrColor='red'
                  disabled={declarationState || (isOwner && document.IntrastatDocumentCategory_Id === 11)}
                  onClick={() => {
                    handleDeleteDocument(document.id);
                  }}
                  margin='5px'
                />                      
              </Btn.Group>
            
            </Table.Cell>
          </Table.Row>
        </>      
    )
}

interface DocumentsProps {
    userAdminOrOp: boolean,
    user: any,
    isOwner: boolean,
    declarationState: boolean, 
    document: any,
    index: number,
    handleShowDocument: (docId) => void;
    handleDeleteDocument: (docId) => void;
    openTagManager?: () => void;
    docList?: any,
  }
  
  export default Documents;