import React, { useEffect, useState } from 'react';
import { useField } from '@formiz/core';

import SimpleInput from 'components/core/SimpleInput/SimpleInput';
import { FormizSimpleInput as Input } from './styles';

const FormizSimpleInput = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const { errorMessage, isValid, isSubmitted, setValue, value } = useField(
    props
  );
  const {
    name,
    type,
    label,
    placeholder,
    required,
    disabled,
    autoFocus,
    ref,
    hide,
    suffix
  } = props;
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
      if(name === 'number'){
        if(value === null || suffix){
          setValue(suffix)
        }
      }
  }, [suffix])

  return (
    <SimpleInput
      ref={ref}
      autoFocus={autoFocus}
      name={name}
      type={type}
      label={label}
      hide={hide}
      placeholder={placeholder}
      value={value ?? ''}
      onChange={(e) => setValue(e.target.value)}
      onBlur={() => setIsTouched(true)}
      required={required}
      disabled={disabled}
      error={showError && errorMessage}
    />
  );
};

export default FormizSimpleInput;
