import button from "./button";
import input from "./input";
import services from "./services";
import countries from "./countries";

export default {
  ...input,
  ...button,
  "app.title": "My ASD",
  ...services,
  ...countries
};
